import React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import capitalize from 'lodash/capitalize';

export default ({ ...props }) => {
  const { archived, status } = props.survey;

  return (
    <Grid container>
      <Grid item xs={6} sx={{ marginTop: 0.6 }}>
        <span className="me-2">Status</span>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: 0.6, textAlign: 'right' }}>
        <Chip sx={{ px: '0.3rem' }} size={'small'} label={archived ? 'Archived' : capitalize(status)} variant="outlined" />
      </Grid>
    </Grid>
  );
};
