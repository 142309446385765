import React from 'react';
import PropTypes from 'prop-types';
import BarChart from './bar_chart';
import LineChart from './line_chart';
import Heatmap from './heatmap';
import { CHART_TYPES } from './utils';
import { Box, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import { smartCrop } from '../../../../helpers/string_utils';
import _merge from 'lodash/merge';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { Chart as ChartJS } from 'chart.js';

// Add the noDataPlugin
const noDataPlugin = {
  id: 'noDataMessage',
  afterDraw: (chart) => {
    if (chart.data.datasets.length === 0 || chart.data.datasets[0].data.length === 0) {
      const {
        ctx,
        chartArea: { left, top, width, height },
      } = chart;
      const text = chart.options.plugins.noDataMessage.text;

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = `${chart.options.plugins.noDataMessage.fontStyle} ${chart.options.plugins.noDataMessage.fontSize}px sans-serif`;
      ctx.fillStyle = chart.options.plugins.noDataMessage.color;
      ctx.fillText(text, left + width / 2, top + height / 2);
      ctx.restore();
    }
  },
};

ChartJS.defaults.datasets.bar = {
  ...ChartJS.defaults.datasets.bar,
  barThickness: 'flex',
  maxBarThickness: 120,
  borderWidth: 0,
};

ChartJS.defaults.datasets.line = {
  ...ChartJS.defaults.datasets.line,
  fill: false,
  borderWidth: 2,
  tension: 0.1,
};

ChartJS.defaults.plugins.legend.labels = {
  ...ChartJS.defaults.plugins.legend.labels,
  usePointStyle: true,
  pointStyle: 'circle',
  pointStyleWidth: 16,
};

const commonChartOptions = () => ({
  plugins: {
    legend: {
      display: false,
      position: 'top',
      align: 'start',
      labels: {
        color: '#333',
      },
      // Remove the default onClick handler
      onClick: () => {},
    },
    legendMargin: { paddingTop: 20 },
    noDataMessage: {
      color: '#666',
      fontSize: 16,
      fontStyle: 'italic',
      text: 'No data to display.',
    },
  },
  layout: {
    padding: {
      top: 0,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: 100,
      grid: {
        display: true,
      },
      ticks: {
        stepSize: 25,
      },
    },
    x: {
      grid: {
        display: false,
      },
      offset: true,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
});

const calculateTotalBars = (datasets) => {
  if (!Array.isArray(datasets)) {
    return 0;
  }

  return datasets.reduce((total, dataset) => {
    if (Array.isArray(dataset.data)) {
      total += dataset.data.length;
    }
    return total;
  }, 0);
};

const renderObservableTypes = (observations) => {
  const observableTypes = [
    ...new Set(observations.map((observation) => observation.observable?.observable_type?.name).filter((oType) => oType != undefined)),
  ];
  return observableTypes.length === 1 ? observableTypes[0] : 'Observee';
};

const Content = ({
  surveys,
  chartConfig,
  chartType,
  groupBy,
  onChartTypeChange,
  onGroupByChange,
  admTrees,
  combinedObservations,
  showHqim,
  selectedNav,
  showAdmNumbers,
  isPointScaleComparison,
  hideClassRoomEngagement,
  filtersMap,
}) => {
  const isPercentScale = () => isPointScaleComparison && selectedNav === 'indicators';

  const renderChart = () => {
    const commonOptions = commonChartOptions();
    const mergedConfig = { ...chartConfig };

    // Merge common options with chart specific options
    if (chartConfig.options) {
      mergedConfig.options = _merge({}, commonOptions, chartConfig.options);
    } else {
      mergedConfig.options = commonOptions;
    }

    const fullLabels = mergedConfig.labels;

    // assume there's room for 1 full label without cropping
    if (mergedConfig.labels.length > 1) {
      mergedConfig.labels = mergedConfig.labels.map((label) => smartCrop(label, { ellipsisPosition: 'head' }));
    }

    mergedConfig.options.plugins.tooltip = {
      enabled: true,
      callbacks: {
        title: function (context) {
          const index = context[0].dataIndex;
          return fullLabels[index];
        },
        label: function (context) {
          const datasetIndex = context.datasetIndex;
          const index = context.dataIndex;
          const label = context.chart.data.datasets[datasetIndex].label || '';
          const value = context.chart.data.datasets[datasetIndex].data[index];
          return isPercentScale() ? `${label}: ${value}` : `${label}: ${value}%`;
        },
      },
    };

    // Add no data message plugin (only for Node Indicators)
    if (selectedNav === 'indicators') {
      mergedConfig.options.plugins.noDataMessage = {
        color: '#666',
        fontSize: 16,
        fontStyle: 'italic',
        text: 'Select indicators to display chart data.',
      };
      mergedConfig.plugins = [noDataPlugin];
    } else {
      mergedConfig.plugins = [];
    }

    // 30px per bar
    const totalBars = calculateTotalBars(mergedConfig.datasets);
    const dynamicWidth = totalBars * 30;

    if (isPercentScale()) {
      // update config to not use a percentage scale
      mergedConfig.options.scales.y.ticks.stepSize = 1;
      mergedConfig.options.scales.y.suggestedMax = 5;
    }

    switch (chartType) {
      case CHART_TYPES.BAR:
        return (
          <div style={{ width: dynamicWidth, minWidth: '100%', height: '400px' }}>
            <BarChart config={{ ...mergedConfig, plugins: [noDataPlugin] }} />
          </div>
        );
      case CHART_TYPES.LINE:
        return (
          <div style={{ minWidth: '100%', height: '400px' }}>
            <LineChart config={{ ...mergedConfig, plugins: [noDataPlugin] }} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={2} alignItems="center" sx={{ mt: { xs: 3, md: 0 } }}>
        {surveys.map((survey, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              sm={4}
              lg={3}
              sx={{
                pt: '0 !important',
              }}
            >
              <TextField
                fullWidth
                value={survey.name}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                size="small"
              />
            </Grid>
            {index < surveys.length - 1 && (
              <Grid item sx={{ pt: '0 !important' }}>
                <FontAwesomeIcon icon={faArrowRightArrowLeft} color="#ccc" />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
      <hr />
      <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom={2} marginTop={3}>
        <FormControl sx={{ width: 180 }} size="small">
          <InputLabel id="group-by-select-label">Group By</InputLabel>
          <Select
            labelId="group-by-select-label"
            id="group-by-select"
            label="Group By"
            value={groupBy}
            onChange={(e) => onGroupByChange(e.target.value)}
          >
            <MenuItem value="default">None</MenuItem>
            {showHqim && <MenuItem value="hqim_in_use">HQIM in Use</MenuItem>}
            {filtersMap?.showSchoolNameFilter && <MenuItem value="school_name">School Name</MenuItem>}
            {filtersMap?.showGradeLevelsFilter && <MenuItem value="grade_level">Grade (individual)</MenuItem>}
            {filtersMap?.showGradeLevelsFilter && <MenuItem value="grade_range">Grade (range)</MenuItem>}
            {filtersMap?.showContentFilter && <MenuItem value="content_area">Content</MenuItem>}
            {filtersMap?.showObservablesFilter && <MenuItem value="observable">{renderObservableTypes(combinedObservations)}</MenuItem>}
            {filtersMap?.showInstructionLanguagesFilter && <MenuItem value="instruction_language">Instruction Language</MenuItem>}
            {filtersMap?.showDiversityFilter && <MenuItem value="diverse_classroom">Diverse Classroom</MenuItem>}
            {filtersMap?.showObserversFilter && <MenuItem value="observer">Observer</MenuItem>}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ width: 120, marginLeft: 1 }}>
          <InputLabel id="chart-type-select-label">Type</InputLabel>
          <Select
            labelId="chart-type-select-label"
            id="chart-type-select"
            value={chartType}
            label="Type"
            onChange={(e) => onChartTypeChange(e.target.value)}
          >
            <MenuItem value={CHART_TYPES.BAR}>Bar</MenuItem>
            <MenuItem value={CHART_TYPES.LINE}>Line</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Paper sx={{ position: 'relative', padding: '1rem', marginBottom: '3rem' }}>
        <Typography variant="h6" gutterBottom>
          {chartConfig.title}
        </Typography>
        <div style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>{renderChart()}</div>
      </Paper>
      <Heatmap
        surveys={surveys}
        groupBy={groupBy}
        admTrees={admTrees}
        combinedObservations={combinedObservations}
        showHqim={showHqim}
        showAdmNumbers={showAdmNumbers}
      />
    </div>
  );
};

const configShape = PropTypes.shape({
  title: PropTypes.string,
  labels: PropTypes.array,
  datasets: PropTypes.array,
  options: PropTypes.object,
});

Content.propTypes = {
  surveys: PropTypes.array.isRequired,
  chartConfig: configShape.isRequired,
  onChartTypeChange: PropTypes.func.isRequired,
  onGroupByChange: PropTypes.func.isRequired,
  chartType: PropTypes.string.isRequired,
  groupBy: PropTypes.string.isRequired,
  admTrees: PropTypes.array.isRequired,
  combinedObservations: PropTypes.array.isRequired,
};

export default Content;
