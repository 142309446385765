import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { legendMargin } from './legend_margin_plugin';

ChartJS.register(CategoryScale, LinearScale, BarElement, legendMargin);

const BarChart = ({ config }) => {
  const data = {
    labels: config.labels,
    datasets: config.datasets,
  };

  const combinedPlugins = [legendMargin, ...(config.plugins || [])];
  return <Bar data={data} options={config.options} plugins={combinedPlugins} height="350" width="0" />;
};

BarChart.propTypes = {
  config: PropTypes.object.isRequired,
};

export default BarChart;
