import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DelegateDialog from './delegate_dialog';
import { getTargetOrgs, saveDelegations } from './api';

const SurveyDelegateDialogContext = createContext();

export const SurveyDelegateDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [originOrgId, setOriginOrgId] = useState(null);
  const [originElement, setOriginElement] = useState({});
  const [targetOrgs, setTargetOrgs] = useState([]);
  const [jobLog, setJobLog] = useState(null);

  /**
   * Opens the delegate dialog.
   * @param {Number} orgId
   * @param {Object} originElement
   * @param {Number} originElement.id
   * @param {String} originElement.type
   * @param {String} originElement.description
   * @param {String} originElement.displayTitle
   */
  const openDelegateDialog = (orgId = null, originElement = {}) => {
    setOriginElement(originElement);
    setOriginOrgId(orgId);
    setOpen(true);
  };

  const closeDelegateDialog = () => {
    setOpen(false);
    setOriginElement({});
  };

  const fetchData = async () => {
    try {
      const data = await getTargetOrgs(originOrgId, originElement.id);
      setTargetOrgs(data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setTargetOrgs(null);
    }
  };

  const saveData = async (delegationsParams, callback = null) => {
    try {
      const data = await saveDelegations(originOrgId, delegationsParams);
      setJobLog({ id: data.id, originElement: { ...originElement } });
      if (callback && typeof callback === 'function') {
        callback();
      }
      closeDelegateDialog();
    } catch (error) {
      console.warn('Failed to save data:', error);
      throw error;
    }
  };

  const clearJobLog = () => {
    setJobLog(null);
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  return (
    <SurveyDelegateDialogContext.Provider
      value={{
        open,
        originElement,
        targetOrgs,
        jobLog,
        openDelegateDialog,
        closeDelegateDialog,
        saveData,
        clearJobLog,
      }}
    >
      {children}
      <DelegateDialog />
    </SurveyDelegateDialogContext.Provider>
  );
};

SurveyDelegateDialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDelegateDialog = () => {
  const context = useContext(SurveyDelegateDialogContext);
  if (!context) {
    console.warn('useDelegateDialog must be used within a SurveyDelegateDialogProvider');
    const noop = () => {};
    return {
      openDelegateDialog: noop,
      closeDelegateDialog: noop,
      saveData: noop,
      clearJobLog: noop,
      open: false,
      originElement: {},
      targetOrgs: [],
      jobLog: null,
    };
  }
  return context;
};
