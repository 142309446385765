import React from 'react';
import AdmNodeRow from './adm_node_row';

const IndicatorRow = ({
  indicator,
  observations,
  showHqimColumns,
  showContentColumns,
  showSummaryOnly,
  showFractions,
  surveyTypeCategoryName,
  setDrawerAdmNode,
  showAdmNumbers,
}) => {
  return (
    <AdmNodeRow
      admNode={indicator}
      observations={observations}
      showHqimColumns={showHqimColumns}
      showContentColumns={showContentColumns}
      showSummaryOnly={showSummaryOnly}
      showFractions={showFractions}
      showAdmNumbers={showAdmNumbers}
      surveyTypeCategoryName={surveyTypeCategoryName}
      setDrawerAdmNode={setDrawerAdmNode}
    />
  );
};

export default IndicatorRow;
