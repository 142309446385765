import React, { useEffect, useState, useCallback } from 'react';
import { Button, FormControl, TextField, Stack, Typography, Select, MenuItem, FormHelperText } from '@mui/material';
import { createDataModelNode, updateDataModelNode } from '../../../apis/care/data_models/api';
import { useConfirmDialog } from '../../modals/confirm_dialog';

export default function AspectCategoryForm({ node, parent, onClose, afterSave, ...props }) {
  const [number, setNumber] = useState(node?.number || '');
  const [name, setName] = useState(node?.name || '');
  const [description, setDescription] = useState(node?.description || '');
  const [errors, setErrors] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const isEditing = !!node;
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  useEffect(() => {
    if (isEditing) {
      if (number !== node.number || name !== node.name || description !== node.description) {
        setHasUnsavedChanges(true);
      }
    } else {
      if (number || name || description) {
        setHasUnsavedChanges(true);
      }
    }
  }, [number, name, description]);

  const handleSave = async (e) => {
    let response;
    if (isEditing) {
      response = await updateDataModelNode({ number, name, description, id: node.id, type: 'Care::AspectCategory' }, `aspect_categories`);
    } else {
      response = await createDataModelNode(
        { number, name, description, parent: { id: parent.id, type: parent.type }, type: 'Care::AspectCategory' },
        'aspect_categories'
      );
    }
    if (response.errors) {
      setErrors(response.errors);
    } else {
      setErrors({});
      afterSave(response);
      setHasUnsavedChanges(false);
    }
  };

  const hasErrors = (prop) => errors && errors[prop]?.length > 0;

  const confirmCancel = () => {
    if (hasUnsavedChanges) {
      showConfirmDialog({
        title: 'Unsaved Changes',
        description: 'You have unsaved changes. Are you sure you want to discard them?',
        actions: [
          {
            label: 'Discard Changes',
            variant: 'text',
            color: 'primary',
            autoFocus: false,
            onClick: async () => {
              onClose();
              hideConfirmDialog();
            },
          },
          {
            label: 'Keep Editing',
            variant: 'contained',
            color: 'primary',
            onClick: () => {
              hideConfirmDialog();
            },
          },
        ],
      });
    } else {
      onClose();
    }
  };

  return (
    <>
      <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack direction="column" spacing={2}>
          {parent && <Typography variant="body">{parent.name}</Typography>}
          <Typography variant="h6"> {node ? `Edit Aspect Category ${node.number}` : 'New Aspect Category'} </Typography>
          <TextField label="Number" variant="outlined" multiline value={number} onChange={(e) => setNumber(e.target.value)} />
          <TextField
            required
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={hasErrors('name')}
            helperText={errors['name']}
          />
          <TextField label="Description" variant="outlined" multiline value={description} onChange={(e) => setDescription(e.target.value)} />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={confirmCancel}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
