import React from 'react';
import AdmTooltip from '../../shared/adm_tooltip';
import RubricToggle from '../../shared/rubric_toggle/index';
import AdmDescription from '../../shared/adm_description';
import Checkbox from '@mui/material/Checkbox';
import SummaryIndicator from './summary_indicator';

const AspectCheckbox = ({ onChange, config, id, isIncluded }) => {
  if (config.question_type === 'title') {
    return null;
  }
  return (
    <Checkbox onChange={(e) => onChange('aspects', id)} disabled={config.required} checked={isIncluded('aspects', id)} data-cy="aspect-checkbox" />
  );
};

const Aspect = ({ aspect, onChange, isIncluded, isAllExpanded, showAdmNumbers }) => {
  return (
    <div className="mt-5-OFF-LDOE mt-4 aspect">
      <div className="d-flex align-items-start justify-content-between gap-2">
        <div className="d-flex justify-content-start align-items-center">
          <div>
            <div className="d-flex align-items-center gap-1">
              <h4 className="headline-color mb-0">
                {showAdmNumbers && aspect.number && <span>{aspect.number}&nbsp;</span>}
                {aspect.name}
              </h4>
              <AdmTooltip config={aspect.survey_config} />
            </div>
            {aspect.survey_config.question_type !== 'title' && (
              <>
                {aspect.rubric && aspect.rubric !== '<p></p>' ? (
                  <RubricToggle rubricHtml={aspect.rubric} />
                ) : (
                  <div>
                    <AdmDescription description={aspect.description} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div style={{ paddingRight: '41px' }}>
          <AspectCheckbox config={aspect.survey_config} id={aspect.id} onChange={onChange} isIncluded={isIncluded} />
        </div>
      </div>
      <div className="mt-3">
        {aspect.summary_indicators.map((summaryIndicator) => (
          <SummaryIndicator
            key={summaryIndicator.id}
            summaryIndicator={summaryIndicator}
            onChange={onChange}
            isIncluded={isIncluded}
            isAllExpanded={isAllExpanded}
            showAdmNumbers={showAdmNumbers}
          />
        ))}
      </div>
    </div>
  );
};

export default Aspect;
