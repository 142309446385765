import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box, ListItemText, Typography } from '@mui/material';

const ConfirmContent = ({ elementTitle, numberOfCheckedItems }) => {
  const listItems = [
    'Checked organizations: These organizations will receive a delegated copy of the template.',
    'Unchecked organizations: The template will be deleted from these organizations.',
    'This dialog closes, and delegation proceeds in the background.',
  ];

  return (
    <div>
      <h6 style={{ fontSize: '1rem' }}>
        You are delegating <em>{elementTitle}</em> into {numberOfCheckedItems} descending organization(s). {"Here's what will happen:"}
      </h6>
      <div>
        <List sx={{ width: '100%', padding: 0, marginTop: 2 }}>
          {listItems.map((text, index) => (
            <ListItem key={index} alignItems="flex-start" sx={{ display: 'flex', flexDirection: 'row', padding: 0, alignItems: 'start', mt: '6px' }}>
              <Box sx={{ minWidth: '24px', marginRight: '1px', textAlign: 'center' }}>
                <Typography variant="body1" component="span" sx={{ fontSize: '1rem' }}>
                  {index + 1}.
                </Typography>
              </Box>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {text}
                  </Typography>
                }
                sx={{ margin: 0 }}
                primaryTypographyProps={{ component: 'div' }}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

ConfirmContent.propTypes = {
  elementTitle: PropTypes.string,
  numberOfCheckedItems: PropTypes.number,
};

export default ConfirmContent;
