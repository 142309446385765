import { useTheme } from '@mui/material';

export const useCommonChartStyles = () => {
  const theme = useTheme();

  const commonColor = {
    white: theme.palette.common.white,
    grey: theme.palette.grey[300],
    black: theme.palette.common.black,
    red: '#E36464',
    yellow: '#F1C477',
    green: '#97C284',
  };

  const commonPaperStyle = {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '260px !important',
    display: 'flex',
    flexDirection: 'column',
  };

  const commonBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    height: '180px !important',
  };

  return { commonPaperStyle, commonBoxStyle, commonColor };
};
