import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Snackbar } from '@mui/material';
import { ConfirmDialogProvider } from '../../../components/modals/confirm_dialog';
import { useConfirmDialog } from '../../../components/modals/confirm_dialog';
import DataModelForm from '../../../components/care/data_model_manager/data_model_form';
import AspectCategoryForm from '../../../components/care/data_model_manager/aspect_category_form';
import AspectForm from '../../../components/care/data_model_manager/aspect_form';
import SummaryIndicatorForm from '../../../components/care/data_model_manager/summary_indicator_form';
import IndicatorForm from '../../../components/care/data_model_manager/indicator_form';
import { DataGridPro, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';
import { Paper, Box, Button, Stack, Typography, Select, MenuItem, Drawer } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faCompress, faPencil, faTrashCan, faCirclePlus, faClone } from '@fortawesome/pro-light-svg-icons';
import { useTheme } from '@mui/material/styles';
import { deleteDataModelNode, cloneDataModel } from '../../../apis/care/data_models/api';
import usePersistColumnSizes from '../../../helpers/data_grid/use_persisted_column_sizes';
import ResetColumnSizesMenu from '../../../helpers/data_grid/reset_column_sizes_menu';
import useDrawerWidth from '../../../helpers/use_drawer_width';
import { alertError } from '../../../components/shared/alert_error';

const drawerContents = {
  'Care::DataModel': DataModelForm,
  'Care::AspectCategory': AspectCategoryForm,
  'Care::Aspect': AspectForm,
  'Care::SummaryIndicator': SummaryIndicatorForm,
  'Care::Indicator': IndicatorForm,
};

const Container = ({ models, content_areas, ...props }) => {
  const theme = useTheme();
  const drawerWidth = useDrawerWidth();

  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const [allModels, setAllModels] = useState(models);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedModelIds, setSelectedModelIds] = useState([models[0].id]);
  const [drawerType, setDrawerType] = useState(null);
  const [node, setNode] = useState(null);
  const [parent, setParent] = useState(null);

  const selectedModels = allModels.filter((model) => selectedModelIds.includes(model.id));

  const flattenTree = (selectedModels) => [
    ...selectedModels,
    ...selectedModels.map((m) => m.aspect_categories).flat(),
    ...selectedModels.map((m) => m.aspects).flat(),
    ...selectedModels.map((m) => m.summary_indicators).flat(),
    ...selectedModels.map((m) => m.indicators).flat(),
  ];

  const [tableRows, setTableRows] = useState(flattenTree(selectedModels));

  const nodeType = (id) => id.split('-')[0];

  const findNode = (id) => tableRows.find((r) => `${r.type}-${r.id}` === id);

  useEffect(() => {
    setTableRows(flattenTree(selectedModels));
  }, [allModels, selectedModelIds]);

  const columns = useMemo(() => [
    { field: 'description', flex: 1, headerName: 'Description', sortable: false },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      valueFormatter: (value, row) => row.type.replace('Care::', '').replace(/([A-Z])/g, ' $1'),
    },
    { field: 'number', headerName: 'Number', sortable: false },
    {
      field: 'actions',
      type: 'actions',
      getActions: ({ id }) => {
        const actions = [
          <GridActionsCellItem
            key={`edit-${id}`}
            icon={<FontAwesomeIcon icon={faPencil} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
            label="Edit"
            onClick={(e) => handleEditClick(id)}
          />,
          <GridActionsCellItem
            key={`delete-${id}`}
            icon={<FontAwesomeIcon icon={faTrashCan} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
            label="Delete"
            onClick={(e) => confirmDelete(id)}
          />,
        ];

        if (nodeType(id) !== 'Care::Indicator') {
          actions.unshift(
            <GridActionsCellItem
              key={`add-child-${id}`}
              icon={<FontAwesomeIcon icon={faCirclePlus} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
              label="Add Child"
              onClick={(e) => handleAddChildClick(id)}
            />
          );
        }

        if (nodeType(id) == 'Care::DataModel') {
          actions.unshift(
            <GridActionsCellItem
              key={`clone-${id}`}
              icon={<FontAwesomeIcon icon={faClone} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
              label="Clone"
              onClick={(e) => handleCloneClick(id)}
            />
          );
        }

        return actions;
      },
      headerName: 'Actions',
      sortable: false,
      align: 'right',
      width: 160,
      resizable: false,
    },
  ]);

  const [adjustedColumns, handleColumnResize, resetColumnWidths] = usePersistColumnSizes('dataModelsManager', columns);

  const groupingColDef = {
    headerName: 'Name',
    flex: 1,
    hideDescendantCount: true,
    valueFormatter: (value) => {
      const row = tableRows.find((r) => `${r.type}-${r.id}` === value);
      return <>{row?.name}</>;
    },
  };

  const ChildComponent = drawerContents[drawerType] || 'div';

  // this is set up to handle easy conversion to a multi-select. Just add the "multiple" prop to the <Select>
  // below and this will work for viewing multiple data models at the same time
  const handleDataModelChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedModelIds(typeof value === 'string' ? value.split(',') : [value]);
  };

  const handleEditClick = (id) => {
    const node = tableRows.find((r) => `${r.type}-${r.id}` === id);
    const parentId = node.hierarchy[node.hierarchy.length - 2];
    const parent = tableRows.find((r) => `${r.type}-${r.id}` === parentId);
    setParent(parent);
    setNode(node);
    setDrawerType(nodeType(id));
  };

  const handleAddChildClick = (typeAndId) => {
    const [type, id] = typeAndId.split('-');
    const parent = tableRows.find((r) => `${r.type}-${r.id}` === typeAndId);
    setNode(null);
    setParent(parent);
    setDrawerType(`${parent.child_type}`);
  };

  const handleCloneClick = async (typeAndId) => {
    const [type, id] = typeAndId.split('-');
    const node = findNode(typeAndId);
    showConfirmDialog({
      title: 'Clone Confirmation',
      description: `Clicking OK will create a clone of ${node.name}, and will take several seconds to several minutes to complete.`,
      actions: [
        {
          label: 'Cancel',
          variant: 'text',
          color: 'primary',
          onClick: () => {
            hideConfirmDialog();
          },
        },
        {
          label: 'OK',
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
          onClick: async () => {
            const response = await cloneDataModel(id, 'data_models');
            if (response.errors) {
              alertError({ error: { response: { data: { message: response.errors } } } });
              hideConfirmDialog();
            } else {
              setAllModels([...allModels, response]);
              setSelectedModelIds([response.id]);
              hideConfirmDialog();
            }
          },
        },
      ],
    });
  };

  const confirmDelete = (id) => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: `Warning: This action will delete this data model element and any lower level elements attached to it.`,
      actions: [
        {
          label: 'Cancel',
          variant: 'text',
          color: 'primary',
          onClick: () => {
            hideConfirmDialog();
          },
        },
        {
          label: 'Delete',
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
          onClick: async () => {
            const node = findNode(id);
            const response = await deleteDataModelNode(node, node.base_url);
            setAllModels(response);
            if (selectedModelIds.includes(node.id)) {
              setSelectedModelIds([allModels[0].id]);
            }
            hideConfirmDialog();
          },
        },
      ],
    });
  };

  const removeNode = (node) => {};

  const resetDrawer = () => {
    setDrawerType(null);
    setNode(null);
    setParent(null);
  };

  const updateOrAddNode = (node) => {
    const updateIdx = tableRows.findIndex((row) => `${row.type}-${row.id}` === `${node.type}-${node.id}`);
    let newRows;

    if (updateIdx >= 0) {
      // update existing node
      newRows = [...tableRows];
      newRows[updateIdx] = node;
      setTableRows(newRows);
      if (node.type === 'Care::DataModel') {
        const modelsUpdateIdx = allModels.findIndex((model) => model.id === node.id);
        const newModels = [...allModels];
        newModels[modelsUpdateIdx] = node;
        setAllModels(newModels);
      }
    } else {
      // add new node
      newRows = [...tableRows, node];
      setTableRows(newRows);

      // set the UI to view the newly created data model
      if (node.type === 'Care::DataModel') {
        setAllModels([...allModels, node]);
        setSelectedModelIds([node.id]);
      }
    }
    resetDrawer();
  };

  return (
    <div>
      <Stack direction="row" spacing={2} fullWidth justifyContent="space-between" sx={{ my: 2 }}>
        <Stack direction="row" spacing={2}>
          <Select onChange={handleDataModelChange} value={selectedModelIds}>
            {allModels.map((model) => (
              <MenuItem key={model.id} value={model.id}>
                {model.name}
              </MenuItem>
            ))}
          </Select>
          <Button variant="contained" color="primary" onClick={(e) => setDrawerType('Care::DataModel')}>
            New
          </Button>
        </Stack>
        <Stack direction="row">
          <Button
            variant="text"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faCompress} />}
            onClick={(e) => {
              setCollapsed(true);
            }}
          >
            Collapse All
          </Button>
          <Button
            variant="text"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faExpand} />}
            onClick={(e) => {
              setCollapsed(false);
            }}
          >
            Expand All
          </Button>
        </Stack>
      </Stack>
      <DataGridPro
        rows={tableRows}
        columns={adjustedColumns}
        onColumnResize={handleColumnResize}
        slots={{
          columnMenu: ResetColumnSizesMenu,
        }}
        slotProps={{
          columnMenu: {
            columnProps: {
              onResetColumnSizes: () => resetColumnWidths(),
            },
          },
        }}
        getRowId={(row) => `${row.type}-${row.id}`}
        treeData
        getTreeDataPath={(row) => row.hierarchy}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={collapsed ? 1 : -1}
        hideFooter
      />
      <Drawer
        anchor={'right'}
        open={drawerType}
        PaperProps={{
          sx: {
            width: drawerWidth,
            padding: 5,
          },
        }}
      >
        <ChildComponent parent={parent} node={node} onClose={() => resetDrawer()} afterSave={updateOrAddNode} contentAreas={content_areas} />
      </Drawer>
    </div>
  );
};

Container.propTypes = {
  models: PropTypes.array,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ConfirmDialogProvider>
        <ThemeProvider theme={themeAttuned}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container {...props}></Container>
          </LocalizationProvider>
        </ThemeProvider>
      </ConfirmDialogProvider>
    </Layout>
  );
};
