import AdmTooltip from '../../shared/adm_tooltip';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

const Indicator = ({ indicator, onChange, isIncluded, showAdmNumbers }) => {
  return (
    <div className="d-flex justify-content-between align-items-start indicator gap-2 py-2" style={{ paddingRight: '24px' }}>
      <div className="d-flex justify-content-start align-items-start">
        {showAdmNumbers && <strong className="me-3 base-color">{indicator.number}</strong>}
        <div>
          <span className="base-color">{indicator.description}</span>
          <AdmTooltip config={indicator.survey_config} />
        </div>
      </div>
      {indicator.survey_config.question_type !== 'title' && (
        <Checkbox
          onChange={(e) => onChange('indicators', indicator.id)}
          disabled={indicator.survey_config.required}
          checked={isIncluded('indicators', indicator.id)}
        />
      )}
    </div>
  );
};

export default Indicator;
