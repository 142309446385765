import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Radio,
  TextField,
  Typography,
  Tabs,
  Tab,
  Grid,
  Box,
  Button,
  Popover,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const accordionStyles = {
  root: {
    minWidth: '180px',
    color: '#333',
    marginTop: '0',
    marginBottom: '0',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
      '&:first-of-type': {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },
    },
  },
  summary: {
    backgroundColor: '#FFFFFF',
    '&.Mui-expanded': {
      marginTop: '0 !important',
    },
  },
};

const getItemId = (item) => {
  return item.id || item;
};

const FilterAccordion = ({
  prop_name,
  title,
  renderLabel,
  items,
  selectedItems,
  isSearchable,
  searchText,
  onSearchChange,
  onFilterChange,
  onClearAll,
  onSelectAll,
  sortFn,
  hasTabs = false,
  gradeRanges,
  onGradeRangeFilterChange,
  selectedGradeRanges,
  useRadioButtons = false,
  selectedDateRange,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [customDateRange, setCustomDateRange] = useState([null, null]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempDateRange, setTempDateRange] = useState([null, null]);

  useEffect(() => {
    if (selectedDateRange !== 'custom') {
      setCustomDateRange([null, null]);
    }
  }, [selectedDateRange]);

  const handleOpenDatePopover = (event) => {
    setAnchorEl(event.currentTarget);
    setTempDateRange(customDateRange);
  };

  const handleCloseDatePopover = () => {
    setAnchorEl(null);
  };

  const handleApplyDateRange = () => {
    setCustomDateRange(tempDateRange);
    onFilterChange('custom', tempDateRange);
    handleCloseDatePopover();
  };

  const renderDateRangePopover = () => (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCloseDatePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ p: 2, width: '300px' }}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <DatePicker
              label="Start Date"
              value={tempDateRange[0]}
              onChange={(newValue) => setTempDateRange([newValue, tempDateRange[1]])}
              slotProps={{ textField: { size: 'small', fullWidth: true } }}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={tempDateRange[1]}
              onChange={(newValue) => setTempDateRange([tempDateRange[0], newValue])}
              slotProps={{ textField: { size: 'small', fullWidth: true } }}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button onClick={handleCloseDatePopover} size="small">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleApplyDateRange} color="primary" variant="contained" size="small">
                Apply
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabStyles = {
    typography: {
      textTransform: 'none',
      fontSize: '0.85rem',
    },
  };

  const handleResetAll = () => {
    setCustomDateRange([null, null]);
    onClearAll(prop_name);
  };

  const RenderSelectOrClearAll = () => {
    if (useRadioButtons) {
      return (
        <div style={{ marginTop: '.7rem' }}>
          <a className="link-dark-no-underline d-inline-flex" onClick={handleResetAll}>
            <Typography variant="body2">Reset</Typography>
          </a>
        </div>
      );
    }

    return (
      <div style={{ marginTop: '.7rem' }}>
        {selectedItems.size > 0 ? (
          <a className="link-dark-no-underline d-inline-flex" onClick={() => onClearAll(prop_name)}>
            <Typography variant="body2">Clear All</Typography>
          </a>
        ) : (
          <a className="link-dark-no-underline d-inline-flex" onClick={() => onSelectAll(items, onFilterChange)}>
            <Typography variant="body2">Select All</Typography>
          </a>
        )}
      </div>
    );
  };

  const handleRadioChange = (itemId) => {
    if (itemId === 'custom') {
      if (!anchorEl) {
        handleOpenDatePopover({ currentTarget: document.getElementById('custom-date-label') });
      }
    } else {
      onFilterChange(itemId);
    }
  };

  const renderIndividualItems = () => {
    const gridColumnSize = hasTabs ? 6 : 12;

    if (sortFn) {
      items.sort((a, b) => {
        const valueA = sortFn(a).toLowerCase();
        const valueB = sortFn(b).toLowerCase();

        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      });
    }

    return (
      <>
        <RenderSelectOrClearAll />
        <Grid container spacing={0}>
          {items.map((item) => (
            <Grid item xs={gridColumnSize} key={getItemId(item)}>
              <FormControlLabel
                key={getItemId(item)}
                control={
                  useRadioButtons ? (
                    <Radio
                      sx={{ marginTop: '-9px', paddingTop: 1 }}
                      checked={selectedItems.has(getItemId(item))}
                      onChange={() => handleRadioChange(getItemId(item))}
                    />
                  ) : (
                    <Checkbox
                      sx={{ marginTop: '-9px', paddingTop: 1 }}
                      checked={selectedItems.has(getItemId(item))}
                      onChange={() => onFilterChange(getItemId(item))}
                    />
                  )
                }
                label={
                  <Typography
                    variant="body2"
                    id={getItemId(item) === 'custom' ? 'custom-date-label' : undefined}
                    style={getItemId(item) === 'custom' ? { cursor: 'pointer' } : {}}
                  >
                    {getItemId(item) === 'custom' && customDateRange[0] && customDateRange[1]
                      ? `${dayjs(customDateRange[0]).format('MM/DD/YYYY')} - ${dayjs(customDateRange[1]).format('MM/DD/YYYY')}`
                      : renderLabel(item)}
                  </Typography>
                }
                labelPlacement="end"
                sx={{ alignItems: 'flex-start', paddingTop: 1 }}
              />
            </Grid>
          ))}
        </Grid>
        {renderDateRangePopover()}
      </>
    );
  };

  const renderGradeRanges = () => {
    return (
      <>
        <Grid container spacing={0}>
          {gradeRanges.map((range) => (
            <Grid item xs={6} key={range}>
              <FormControlLabel
                control={
                  <Checkbox sx={{ marginTop: '-9px' }} checked={selectedGradeRanges.has(range)} onChange={() => onGradeRangeFilterChange(range)} />
                }
                label={<Typography variant="body2">{range}</Typography>}
                labelPlacement="end"
                sx={{ alignItems: 'flex-start', paddingTop: 1 }}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Accordion sx={accordionStyles.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: '#67bbb9' }} />}
        aria-controls={`${prop_name}-content`}
        id={`${prop_name}-header`}
        sx={accordionStyles.summary}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isSearchable && (
          <TextField
            placeholder="Search"
            value={searchText}
            onChange={onSearchChange}
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            sx={{ marginBottom: '1rem', marginTop: '0 !important' }}
            InputProps={{
              style: {
                backgroundColor: 'white',
                border: 'none',
              },
            }}
          />
        )}
        {hasTabs ? (
          <>
            <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth" aria-label="Grade Level Tabs">
              <Tab label="Individual" sx={tabStyles.typography} />
              <Tab label="Ranges" sx={tabStyles.typography} />
            </Tabs>
            {activeTab === 0 ? renderIndividualItems() : renderGradeRanges()}
          </>
        ) : (
          renderIndividualItems()
        )}
      </AccordionDetails>
    </Accordion>
  );
};

FilterAccordion.propTypes = {
  prop_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  renderLabel: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.instanceOf(Set).isRequired,
  isSearchable: PropTypes.bool,
  searchText: PropTypes.string,
  onSearchChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onClearAll: PropTypes.func,
  onSelectAll: PropTypes.func,
  useRadioButtons: PropTypes.bool,
};

export default FilterAccordion;
