import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Box, Stack, Typography, Button, Drawer } from '@mui/material';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CareNav from '../../../components/care/shared/nav';
import ObservablesTable from '../../../components/care/observables/observables_table';
import useDrawerWidth from '../../../helpers/use_drawer_width';
import ObservableForm from '../../../components/care/observables/observable_form';
import SnackMessage from '../../../components/shared/snack_message';
import { ConfirmDialogProvider, useConfirmDialog } from '../../../components/modals/confirm_dialog';
import { deleteObservable } from '../../../apis/care/observables/api';

const Container = ({ ...props }) => {
  const drawerWidth = useDrawerWidth();
  const confirmCancelRef = useRef(null);
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const {
    org_users,
    observables: initialObservables,
    observable_label,
    observable_types,
    assignable_users: initialAssignableUsers,
    race_ethnicity_options,
    gender_options,
    base_url,
  } = props;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [observable, setObservable] = useState(null);
  const [assignableUsers, setAssignableUsers] = useState(initialAssignableUsers);
  const [observables, setObservables] = useState(initialObservables);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const countPerType = observables.reduce((acc, item) => {
    acc[item.observable_type.display_label] = (acc[item.observable_type.display_label] || 0) + 1;
    return acc;
  }, {});

  const addIcon = <FontAwesomeIcon icon={faPlus} style={{ fontSize: '0.9rem' }} />;

  const renderCountPerType = () => {
    const keys = Object.keys(countPerType);
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {keys.map((key, index) => (
          <React.Fragment key={key}>
            <Typography variant="body1" component="span" sx={{ display: 'flex', alignItems: 'center' }}>
              {key} {countPerType[key]}
            </Typography>
            {index < keys.length - 1 && (
              <Typography variant="body2" component="span" sx={{ mx: 1 }}>
                |
              </Typography>
            )}
          </React.Fragment>
        ))}
      </Stack>
    );
  };

  const handleAdd = () => {
    setObservable(null);
    setDrawerOpen(true);
  };

  const handleEdit = (observableId) => {
    const observable = observables.find((item) => item.id === observableId);
    if (observable) {
      setObservable(observable);
      setDrawerOpen(true);
    }
  };

  const handleDelete = (observableId) => {
    const observable = observables.find((item) => item.id === observableId);
    if (!observable) return;
    if (observable.observations_count > 0) {
      showConfirmDialog({
        title: 'Delete Observable',
        description: `The observable "${observable.name}" has observations that must be deleted before the observable can be deleted.`,
        actions: [
          {
            label: 'Ok',
            variant: 'contained',
            color: 'primary',
            autoFocus: true,
            onClick: () => {
              hideConfirmDialog();
            },
          },
        ],
      });
    } else {
      showConfirmDialog({
        title: 'Delete Observable',
        description: `Are you sure you want to delete the observable "${observable.name}"?`,
        actions: [
          {
            label: 'Cancel',
            variant: 'text',
            color: 'primary',
            autoFocus: false,
            onClick: () => {
              hideConfirmDialog();
            },
          },
          {
            label: 'Delete',
            variant: 'contained',
            color: 'primary',
            onClick: async () => {
              hideConfirmDialog();
              await deleteObservable(observableId, base_url);
              setObservables((prevObservables) => prevObservables.filter((item) => item.id !== observableId));
              handleOpenSnackbar('Deleted!');
            },
          },
        ],
      });
    }
  };

  const updateOrAddObservable = (observableResponse) => {
    resetDrawer();

    if (observableResponse?.observable_entity && observableResponse?.observable_entity?.type === 'User') {
      setAssignableUsers((prevAssignableUsers) => prevAssignableUsers.filter((user) => user.email !== observableResponse.observable_entity.email));
    }

    const index = observables.findIndex((item) => item.id === observableResponse.id);
    const updatedObservables = [...observables];

    setObservables((prevObservables) => {
      if (index > -1) {
        updatedObservables[index] = observableResponse;
        return updatedObservables;
      } else {
        return [...prevObservables, observableResponse];
      }
    });

    handleOpenSnackbar('Saved!');
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  const handleOpenSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const resetDrawer = () => {
    setDrawerOpen(false);
    setObservable(null);
  };

  return (
    <Layout>
      <CareNav {...props} back_url={''} />
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className="mt-5">
        <Button variant="contained" color="primary" onClick={handleAdd} startIcon={addIcon}>
          Add
        </Button>
        {renderCountPerType()}
      </Stack>
      <Box sx={{ marginTop: 2 }}>
        <ObservablesTable observables={observables} onEdit={(id) => handleEdit(id)} onDelete={(id) => handleDelete(id)} />
      </Box>
      <Drawer
        anchor={'right'}
        open={drawerOpen}
        onClose={() => confirmCancelRef.current()}
        PaperProps={{
          sx: {
            width: drawerWidth,
          },
        }}
      >
        <ObservableForm
          observable={observable}
          description2={observable?.description}
          observable_label={observable_label}
          observable_types={observable_types}
          assignable_users={assignableUsers}
          race_ethnicity_options={race_ethnicity_options}
          gender_options={gender_options}
          org_users={org_users}
          base_url={base_url}
          afterSave={updateOrAddObservable}
          onClose={resetDrawer}
          confirmCancelRef={confirmCancelRef}
        />
      </Drawer>
      <SnackMessage isOpen={snackbarOpen} message={snackbarMessage} handleClose={handleCloseSnackbar} severity={snackbarSeverity} />
    </Layout>
  );
};

Container.propTypes = {
  observables: PropTypes.array,
  observable_labels: PropTypes.string,
  observable_types: PropTypes.array,
  assignable_users: PropTypes.array,
  race_ethnicity_options: PropTypes.array,
  gender_options: PropTypes.array,
  org_users: PropTypes.array,
  permission_props: PropTypes.object.isRequired,
  page_props: PropTypes.object.isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ConfirmDialogProvider>
            <Container {...props}></Container>
          </ConfirmDialogProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Layout>
  );
};
