import React from 'react';
import Button from '@mui/material/Button';

export default ({ ...props }) => {
  const isOpen = props.survey && props.survey.status === 'enabled';
  return (
    <Button
      fullWidth
      variant="contained"
      size="large"
      sx={{ minWidth: '150px' }}
      onClick={() => props.onClick(isOpen ? 'disabled' : 'enabled')}
      data-cy="button-open-close-survey"
    >
      {isOpen ? 'Disable' : 'Enable'}
    </Button>
  );
};
