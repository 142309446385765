import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import AspectCategoryDrawerContents from '../../../components/care/reports/adm_node_detail_drawers/aspect_category';
import ChartDrawerContents from '../../../components/care/reports/adm_node_detail_drawers/chart_drawer_contents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import { observationsWithResponseForAdmNode } from './observation_adm_node_filter_utils';
import ObservationDialogAdapter from '../../../components/care/observations/observation_dialog_adapter';
import { ConfirmDialogProvider } from '../../modals/confirm_dialog';
import RubricToggle from '../shared/rubric_toggle/index';
import { getSurvey } from '../../../apis/care/surveys/api';

const drawerContents = {
  'Care::AspectCategory': AspectCategoryDrawerContents,
  'Care::Aspect': ChartDrawerContents,
  'Care::SummaryIndicator': ChartDrawerContents,
  'Care::Indicator': ChartDrawerContents,
};

const AdmNodeDrawer = ({
  admNode,
  isDrawerOpen,
  setIsDrawerOpen,
  observations,
  surveys,
  baseUrl,
  leads,
  recipients,
  showSummaryOnly,
  gradeLevels,
  ...props
}) => {
  const [isObservationDialogOpen, setIsObservationDialogOpen] = useState(false);
  const [observation, setObservation] = useState(null);
  const [survey, setSurvey] = useState(null);

  if (admNode === null) {
    return null;
  }

  const ChildComponent = drawerContents[admNode.type] || 'div';

  return (
    <Drawer
      open={admNode !== null && isDrawerOpen}
      anchor="right"
      PaperProps={{
        sx: {
          width: { xs: '80%', sm: '80%', md: '60%', lg: '40%', xl: '30%' },
          minWidth: { md: '900px !important' },
          padding: 5,
        },
      }}
      onClose={(e) => setIsDrawerOpen(false)}
    >
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faXmark} onClick={(e) => setIsDrawerOpen(false)} />
      </Stack>
      <Typography variant="h4" sx={{ marginTop: 1 }}>
        {admNode.name}
      </Typography>
      <Box sx={{ marginBottom: 5 }}>
        <Typography variant="h6" sx={{ fontWeight: 'normal' }}>
          {admNode.description}
        </Typography>
        {admNode?.rubric && admNode?.rubric !== '<p></p>' && <RubricToggle rubricHtml={admNode.rubric} />}
      </Box>
      <ChildComponent
        admNode={admNode}
        observations={observationsWithResponseForAdmNode(observations, admNode)}
        setObservation={async (observation) => {
          const surveyResponse = await getSurvey(observation.survey_id);
          setSurvey(surveyResponse);
          setObservation(observation);
          setIsObservationDialogOpen(true);
        }}
        observablesLabel={props.observablesLabel}
        surveys={surveys}
        showSummaryOnly={showSummaryOnly}
        gradeLevels={gradeLevels}
      />
      {isObservationDialogOpen && (
        <ConfirmDialogProvider>
          <ObservationDialogAdapter
            survey={survey}
            observation={observation}
            baseUrl={baseUrl}
            onClose={(e) => setIsObservationDialogOpen(false)}
            leads={leads}
            recipients={recipients}
            readOnly
          />
        </ConfirmDialogProvider>
      )}
    </Drawer>
  );
};

AdmNodeDrawer.propTypes = {
  observations: PropTypes.array.isRequired,
  admNode: PropTypes.object,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  survey: PropTypes.object,
  baseUrl: PropTypes.string,
};

export default AdmNodeDrawer;
