import * as LDClient from 'launchdarkly-js-client-sdk';
import { getCurrentUser } from '../helpers/get_current_user.js';
import { getCurrentOrg } from '../helpers/get_current_org.js';

export const initFeatureFlagClient = () => {
  const { id, email } = getCurrentUser();
  const { id: orgId, name: orgName } = getCurrentOrg();
  const clientId = document.querySelector('meta[name="launch_darkly_client_id"]').content;

  const context = {
    kind: 'multi',
    user: {
      key: `user-${id}`,
      email: email,
    },
    org: {
      name: orgName,
      key: `org-${orgId}`,
    },
  };

  window.featureFlagClient = LDClient.initialize(clientId, context);
};
