export const getColor = (number) => {
  const colorRanges = {
    red: {
      start: 0,
      end: 45,
      startColor: [227, 100, 100],
      endColor: [252, 149, 134],
    },
    orange: {
      start: 45,
      end: 60,
      startColor: [252, 149, 134],
      endColor: [242, 202, 104],
    },
    yellow: {
      start: 60,
      end: 75,
      startColor: [242, 202, 104],
      endColor: [255, 238, 125],
    },
    green: {
      start: 75,
      end: 100,
      startColor: [255, 238, 125],
      endColor: [151, 194, 132],
    },
  };

  // Helper function to interpolate between two colors
  function interpolateColor(color1, color2, factor) {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  }

  // Convert a RGB array to a HEX string
  function rgbToHex(rgb) {
    return (
      '#' +
      rgb
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
    );
  }

  // Determine the current range and interpolate the color
  let currentRange;
  for (const range in colorRanges) {
    if (number >= colorRanges[range].start && number <= colorRanges[range].end) {
      currentRange = colorRanges[range];
      break;
    }
  }

  if (!currentRange) {
    return null;
  }

  // Calculate the factor for interpolation
  const rangeSpan = currentRange.end - currentRange.start;
  const normalizedNumber = (number - currentRange.start) / rangeSpan;
  const color = interpolateColor(currentRange.startColor, currentRange.endColor, normalizedNumber);
  return rgbToHex(color);
};

export const getPointScaleColor = (number, numPoints = 5, zeroBased = false) => {
  if (numPoints === 1) {
    const roundedNumber = parseFloat(number.toFixed(2));
    return getColor(roundedNumber * 100);
  }

  const pointScaleToColorMap = () => {
    if (numPoints === 7) {
      return {
        1.0: 0,
        1.5: 20,
        2.0: 30,
        2.5: 40,
        3.0: 50,
        3.5: 60,
        4.0: 70,
        4.5: 75,
        5.0: 80,
        5.5: 85,
        6.0: 90,
        6.5: 95,
        7.0: 100,
      };
    }
    if (numPoints === 5) {
      return {
        1: 0,
        1.5: 50,
        2: 60,
        2.5: 70,
        3: 80,
        3.5: 85,
        4: 90,
        4.5: 95,
        5: 100,
      };
    } else if (numPoints === 4) {
      return {
        1: 0,
        1.5: 50,
        2: 60,
        2.5: 70,
        3: 80,
        3.5: 90,
        4: 100,
      };
    } else if (numPoints === 3) {
      return zeroBased
        ? {
            0: 0,
            1: 30,
            1.5: 60,
            2: 75,
            2.5: 85,
            3: 100,
          }
        : {
            1: 0,
            1.5: 60,
            2: 75,
            2.5: 85,
            3: 100,
          };
    }
  };

  const colorMap = pointScaleToColorMap();

  if (zeroBased) {
    // Try to get exact bucket value
    if (colorMap[number] !== undefined) {
      return getColor(colorMap[number]);
    }
    // default to continuous scale
    return getColor((number / numPoints) * 100);
  }

  return getColor(colorMap[number]);
};
