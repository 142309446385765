import dayjs from 'dayjs';

import React, { useEffect, useState } from 'react';
import { Grid, Button, Box, FormControl, TextField, Stack, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import ListSubheader from '@mui/material/ListSubheader';
import { DatePicker } from '@mui/x-date-pickers-pro';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => (option.name ? option.name.toLowerCase() : ''),
});

export default function AddGoals({
  baseUrl,
  observables,
  goalTypes,
  goalIndicators,
  leads,
  onSave,
  isSubmitting,
  resetForm = false,
  goalDescriptorsEnabled,
  errors,
}) {
  const [goalObservables, setGoalObservables] = useState([]);
  const [description, setDescription] = useState('');
  const [goalType, setGoalType] = useState(null);
  const [aspect, setAspect] = useState(null);
  const [selectedGoalIndicator, setSelectedGoalIndicator] = useState(null);
  const [summaryIndicator, setSummaryIndicator] = useState(null);
  const [coach, setCoach] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  const groupedIndicators = Object.groupBy(goalIndicators, (indicator) => indicator.aspect_category.name);

  useEffect(() => {
    if (resetForm) {
      setGoalObservables([]);
      setDescription('');
      setGoalType('');
      setAspect('');
      setSelectedGoalIndicator(null);
      setSummaryIndicator('');
      setCoach([]);
      setDueDate(null);
    }
  }, [resetForm]);

  const addObservable = (e, value, reason) => {
    if (reason === 'selectOption' && value.filter((option) => option.id === 'all').length > 0) {
      setGoalObservables(observables);
    } else {
      setGoalObservables(value);
    }
  };

  const handleGoalIndicatorChange = (e) => {
    const selectedId = e.target.value;
    setAspect(selectedId);

    const goalIndicator = goalIndicators.find((indicator) => indicator.id === selectedId);
    setSelectedGoalIndicator(goalIndicator);
  };

  const menuItemStyle = {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const fieldStyle = {
    backgroundColor: 'white',
  };

  const handleSave = (e) => {
    e.preventDefault();
    const payload = {
      description: description,
      due_date: dueDate,
      goal_type_id: goalType,
      aspect_id: aspect,
      summary_indicator_id: summaryIndicator,
      coach_id: coach,
      observable_ids: goalObservables.map((observable) => observable.id),
    };
    onSave(payload);
  };

  const groupedAspects = () => {
    const grouped = [];
    Object.keys(groupedIndicators).forEach((aspectCategory, aspectCategoryIndex) => {
      grouped.push(<ListSubheader key={`ac${aspectCategoryIndex}`}>{aspectCategory}</ListSubheader>);
      groupedIndicators[aspectCategory].forEach((goalIndicator, aspectIndex) =>
        grouped.push(
          <MenuItem key={`aspect${goalIndicator.id}`} value={goalIndicator.id} sx={{ pl: 5 }}>
            <div style={menuItemStyle}>{goalIndicator.name}</div>
          </MenuItem>
        )
      );
    });

    return grouped;
  };

  return (
    <Box sx={{ marginTop: 5 }} className="add-goals-form">
      <Typography variant="h6" className="blue-header" sx={{ mb: 2 }}>
        Add Goals
      </Typography>
      <Box sx={{ paddingX: 5, paddingY: 5, borderRadius: 2, border: '1px solid #ccc', backgroundColor: '#F5F8FA' }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={[{ id: 'all', name: 'All Teachers' }, ...observables.sort((a, b) => a.name.localeCompare(b.name))]}
                    filterOptions={filterOptions}
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} label="Teachers" required error={errors.observable?.length > 0} />}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={addObservable}
                    value={goalObservables}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id} value={item.id} id={item.id}>
                        {item.name}
                      </li>
                    )}
                    multiple
                    size="small"
                    sx={fieldStyle}
                  />
                  {errors.observable?.length > 0 && <FormHelperText sx={{ color: '#d32f2f' }}>Please add at least one teacher.</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth required size="small">
                    <InputLabel id="goal-type-select-label">Goal Type</InputLabel>
                    <Select
                      labelId="goal-type-select-label"
                      id="goal-type-select"
                      value={goalType || ''}
                      label="Goal Type"
                      onChange={(e) => setGoalType(e.target.value)}
                      sx={fieldStyle}
                      error={errors.goal_type_id?.length > 0}
                    >
                      {goalTypes.map((goal, index) => (
                        <MenuItem key={index} value={goal.id}>
                          <div style={menuItemStyle}>{goal.name}</div>
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.goal_type_id?.length > 0 && <FormHelperText sx={{ color: '#d32f2f' }}>Goal type must exist.</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={goalDescriptorsEnabled ? 6 : 12}>
                  <FormControl fullWidth required size="small">
                    <InputLabel id="goal-indicator-select-label">Goal Indicator</InputLabel>
                    <Select
                      labelId="goal-indicator-select-label"
                      id="goal-indicator-select"
                      value={aspect || ''}
                      label="Goal Indicator"
                      onChange={handleGoalIndicatorChange}
                      sx={fieldStyle}
                      error={errors.aspect_id?.length > 0}
                    >
                      {groupedAspects()}
                    </Select>
                    {errors.aspect_id?.length > 0 && <FormHelperText sx={{ color: '#d32f2f' }}>Goal indicator must exist.</FormHelperText>}
                  </FormControl>
                </Grid>

                {goalDescriptorsEnabled && (
                  <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="goal-descriptor-select-label">Descriptor</InputLabel>
                      <Select
                        labelId="descriptor-select-label"
                        id="descriptor-select"
                        value={summaryIndicator || ''}
                        label="Descriptor"
                        onChange={(e) => setSummaryIndicator(e.target.value)}
                        sx={fieldStyle}
                        disabled={!selectedGoalIndicator}
                      >
                        {selectedGoalIndicator?.summary_indicators?.map((summaryIndicator, index) => (
                          <MenuItem key={index} value={summaryIndicator.id}>
                            <div style={menuItemStyle}>{summaryIndicator.name}</div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <FormControl fullWidth required size="small">
                    <InputLabel id="lead-select-label">Lead</InputLabel>
                    <Select
                      labelId="lead-select-label"
                      id="lead-select"
                      value={coach || ''}
                      label="Lead"
                      onChange={(e) => setCoach(e.target.value)}
                      sx={fieldStyle}
                      error={errors.coach_id?.length > 0}
                    >
                      {leads
                        ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
                        .map((user, index) => (
                          <MenuItem key={index} value={user.id}>
                            <div style={menuItemStyle}>{user.full_name}</div>
                          </MenuItem>
                        ))}
                    </Select>
                    {errors.coach_id?.length > 0 && <FormHelperText sx={{ color: '#d32f2f' }}>Lead must exist.</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth size="small" sx={fieldStyle}>
                    <DatePicker
                      label="Due date"
                      value={null}
                      minDate={dayjs().add(1, 'day')}
                      onChange={(date) => {
                        const value = date ? date.format('YYYY-MM-DD') : null;
                        setDueDate(value);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          required: true,
                          InputLabelProps: { shrink: true },
                          size: 'small',
                          error: errors.due_date?.length > 0,
                          helperText: errors.due_date?.length > 0 ? 'Due date cannot be blank.' : '',
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <TextField
                      label="Description"
                      multiline
                      rows={2}
                      variant="outlined"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      sx={fieldStyle}
                      required
                      error={errors.description?.length > 0}
                      helperText={errors.description?.length > 0 ? 'Description cannot be blank.' : ''}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ marginTop: 4 }}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={handleSave} disabled={isSubmitting}>
                Add Goal
              </Button>
            </Stack>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}
