import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Box } from '@mui/material';
import AnimatedDots from '../../shared/animated_dots';

const ActionLink = ({ icon, onClick, text, isLoading = false }) => {
  return (
    <a className="link-dark-no-underline d-inline-flex" onClick={onClick}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={icon} color="#585858" />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <span>{text}</span>
          {isLoading && <AnimatedDots />}
        </Box>
      </Stack>
    </a>
  );
};

export default ActionLink;
