import React, { forwardRef } from 'react';
import { Box, Chip } from '@mui/material';
import AnimatedDots from '../../animated_dots';

const sxDefault = {
  height: '20px',
  fontSize: '11px',
  fontWeight: 500,
  textTransform: 'capitalize',
  borderRadius: 16,
  margin: 0,
  padding: 0,
  opacity: '0.7',
  color: '#488281',
  border: '1px solid #488281',
  backgroundColor: 'transparent',
  '& .MuiChip-label': {
    paddingLeft: '6px',
    paddingRight: '6px',
    maxWidth: '270px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const sxInProgress = {
  color: '#707070',
  border: '1px solid #ccc',
};

const DelegatedTag = forwardRef(({ isInProgress = false }, ref) => {
  const mergedSx = isInProgress ? { ...sxDefault, ...sxInProgress } : sxDefault;
  const label = isInProgress ? 'Delegating' : 'Delegated';
  return (
    <Chip
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {label}
          {isInProgress && <AnimatedDots color1={'#ccc'} color2={'#afafaf'} />}
        </Box>
      }
      title={label}
      size="small"
      sx={mergedSx}
      ref={ref}
    />
  );
});

export default DelegatedTag;
