import { getHeaders } from '../../../../apis/headers';

const baseUrl = (orgId) => `/orgs/${orgId}/care/survey_delegations`;

export const getTargetOrgs = async (orgId, surveyId) => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  const response = await fetch(`${baseUrl(orgId)}/target_orgs?survey_id=${surveyId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const saveDelegations = async (orgId, delegationsParams) => {
  const response = await fetch(`/orgs/${orgId}/care/survey_delegations`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(delegationsParams),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const getDelegatedOrgElements = async (orgId) => {
  const response = await fetch(`/org/${orgId}/delegations/delegated_plan_elements`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
