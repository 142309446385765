import { Box, Typography } from '@mui/material';
import React from 'react';

export default function NoObservationsMessage() {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '1rem',
      }}
    >
      <Typography variant="body2" color="textSecondary" align="center">
        No observations meet the current filter criteria.
      </Typography>
    </Box>
  );
}
