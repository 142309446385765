import { applyOpacity, MIN_OPACITY, COLORS, CHART_TYPES } from './utils';
import {
  observationsWithResponseForAdmNode,
  observationsWithResponseForAdmNodeByValue,
  sumResponsesByAdmNode,
} from '../observation_adm_node_filter_utils';
import { groupObservationsBy } from '../heatmaps/utils';
import { gradeLevelToNumber, hqimInUseToNumber, gradeRanges } from '../filters/utils';

const isGrouped = (groupBy) => groupBy !== 'default';

const isNietSurveyType = (survey) => survey?.survey_type_name?.toLowerCase().includes('niet');

const getSortFunction = (groupBy) => {
  const diversityToNumber = {
    yes: 1,
    no: 2,
    unclear: 3,
  };

  switch (groupBy) {
    case 'diverse_classroom':
      return (a, b) => diversityToNumber[a.toLowerCase()] - diversityToNumber[b.toLowerCase()];
    case 'grade_range':
      return (a, b) => gradeRanges.indexOf(a) - gradeRanges.indexOf(b);
    case 'grade_level':
      return (a, b) => gradeLevelToNumber(a) - gradeLevelToNumber(b);
    case 'hqim_in_use':
      return (a, b) => hqimInUseToNumber(a.toLowerCase()) - hqimInUseToNumber(b.toLowerCase());
    default:
      return (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());
  }
};

const calculatePercentageBasedOnProp = (observations, prop) => {
  const totalCount = observations.length;
  const yesCount = observations.filter((o) => o[prop] === true).length;
  const percent = totalCount > 0 ? (yesCount / totalCount) * 100 : 0;
  return Math.round(percent);
};

const calculateEngagedStudentsPercentage = (observations) => {
  const totalCount = observations.length;
  const countMoreThan80 = observations.reduce((acc, obs) => {
    const pct = (obs.number_of_engaged_students / obs.number_of_students) * 100;
    return pct > 79 ? acc + 1 : acc;
  }, 0);

  const percent = totalCount > 0 ? (countMoreThan80 / totalCount) * 100 : 0;
  return Math.round(percent);
};

const getBarChartConfig = (surveys, metric) => {
  let labels = [];
  let data = [];

  if (metric === 'hqim_in_use') {
    data = surveys.map((survey) => {
      labels.push(survey.name);
      return calculatePercentageBasedOnProp(survey.observations, 'hqim_in_use');
    });
  } else if (metric === 'students_engaged_in_class') {
    data = surveys.map((survey) => {
      labels.push(survey.name);
      return calculateEngagedStudentsPercentage(survey.observations);
    });
  }

  const titleMap = {
    hqim_in_use: 'HQIM In Use',
    students_engaged_in_class: 'Classrooms with at least 80% Student Engagement',
  };

  return {
    title: titleMap[metric],
    labels: labels,
    datasets: [
      {
        label: `${titleMap[metric].toUpperCase()}`,
        data: data,
        backgroundColor: COLORS.cyan,
        borderColor: COLORS.cyan,
        borderWidth: 2,
      },
    ],
  };
};

const getLineChartConfig = (surveys, metric) => {
  let labels = [];
  let data = [];

  if (metric === 'hqim_in_use') {
    data = surveys.map((survey) => {
      labels.push(survey.name);
      return calculatePercentageBasedOnProp(survey.observations, 'hqim_in_use');
    });
  } else if (metric === 'students_engaged_in_class') {
    data = surveys.map((survey) => {
      labels.push(survey.name);
      return calculateEngagedStudentsPercentage(survey.observations);
    });
  }

  const titleMap = {
    hqim_in_use: 'HQIM In Use',
    students_engaged_in_class: 'Students Engaged',
  };

  return {
    title: titleMap[metric],
    labels: labels,
    datasets: [
      {
        label: `${titleMap[metric].toUpperCase()}`,
        data: data,
        backgroundColor: COLORS.cyan,
        borderColor: COLORS.cyan,
      },
    ],
  };
};

const getGroupedBarChartConfig = (surveys, metric, groupBy) => {
  let allGroupNames = new Set();
  surveys.forEach((survey) => {
    const groupedObservations = groupObservationsBy(groupBy, survey.observations);
    Object.keys(groupedObservations).forEach((groupName) => allGroupNames.add(groupName));
  });

  let datasets = surveys.map((survey, surveyIndex) => {
    const groupedObservations = groupObservationsBy(groupBy, survey.observations);
    const surveysLength = surveys.length;
    const opacity = MIN_OPACITY + (1 - MIN_OPACITY) * (surveyIndex / (surveysLength - 1));

    let data = [];
    let backgroundColors = [];
    let borderColors = [];

    Array.from(allGroupNames)
      .sort()
      .forEach((groupName) => {
        const observations = groupedObservations[groupName] || [];
        let percentage;

        if (metric === 'hqim_in_use') {
          percentage = calculatePercentageBasedOnProp(observations, 'hqim_in_use');
        } else if (metric === 'students_engaged_in_class') {
          percentage = calculateEngagedStudentsPercentage(observations);
        } else {
          percentage = 0; // Fallback for unsupported metrics
        }

        if (percentage > 0) {
          backgroundColors.push(applyOpacity(COLORS.cyan, opacity));
          borderColors.push(applyOpacity(COLORS.cyan, opacity));
          data.push(percentage);
        }
      });

    return {
      label: survey.name,
      data: data,
      backgroundColor: backgroundColors,
      borderColor: borderColors,
    };
  });

  const titleMap = {
    hqim_in_use: 'HQIM In Use',
    students_engaged_in_class: 'Classrooms with at least 80% Student Engagement',
  };
  let sortedLabels = Array.from(allGroupNames).sort(getSortFunction(groupBy));

  return {
    title: titleMap[metric],
    labels: sortedLabels,
    datasets: datasets,
  };
};

const getGroupedLineChartConfig = (surveys, metric, groupBy) => {
  let groupNames = new Set();
  surveys.forEach((survey) => {
    const groupedObservations = groupObservationsBy(groupBy, survey.observations);
    Object.keys(groupedObservations).forEach((groupName) => groupNames.add(groupName));
  });

  let datasets = Array.from(groupNames)
    .sort()
    .map((groupName) => {
      const data = surveys.map((survey) => {
        const observations = groupObservationsBy(groupBy, survey.observations)[groupName] || [];
        let percentage;
        if (metric === 'hqim_in_use') {
          percentage = calculatePercentageBasedOnProp(observations, 'hqim_in_use');
        } else if (metric === 'students_engaged_in_class') {
          percentage = calculateEngagedStudentsPercentage(observations);
        } else {
          percentage = 0; // Fallback for unsupported metrics
        }
        return percentage;
      });

      return {
        label: groupName,
        data: data,
        borderColor: COLORS.cyan,
      };
    });

  const titleMap = {
    hqim_in_use: 'HQIM In Use',
    students_engaged_in_class: 'Classrooms with at least 80% Student Engagement',
  };

  return {
    title: titleMap[metric],
    labels: surveys.map((survey) => survey.name),
    datasets: datasets,
  };
};

const getGroupedIndicatorsBarChartConfig = (surveys, selectedNodes, groupBy, is5PointScaleComparison) => {
  let allGroupNames = new Set();
  let datasets = [];
  const colorKeys = Object.keys(COLORS);

  // Collect all unique group names
  surveys.forEach((survey) => {
    const groupedObservations = groupObservationsBy(groupBy, survey.observations);
    Object.keys(groupedObservations).forEach((groupName) => allGroupNames.add(groupName));
  });

  const labels = Array.from(allGroupNames).sort(getSortFunction(groupBy));

  // Preparing datasets
  selectedNodes.forEach((node, nodeIndex) => {
    surveys.forEach((survey, surveyIndex) => {
      const colorKey = colorKeys[nodeIndex % colorKeys.length];
      const color = COLORS[colorKey];
      const opacity = MIN_OPACITY + (1 - MIN_OPACITY) * (surveyIndex / (surveys.length - 1));
      const backgroundColor = applyOpacity(color, opacity);

      const isNietSurvey = isNietSurveyType(survey);

      // Initialize data array for this dataset
      let data = new Array(labels.length).fill(null);
      labels.forEach((label, labelIndex) => {
        const observations = groupObservationsBy(groupBy, survey.observations)[label] || [];
        const relevantObservations = observationsWithResponseForAdmNode(observations, node);
        const observationCount = relevantObservations.length;
        let percentage;

        if (isNietSurvey || is5PointScaleComparison) {
          const sumResponses = sumResponsesByAdmNode(observations, node);
          const averageValue = observationCount > 0 ? sumResponses / observationCount : 0;
          percentage = averageValue.toFixed(1);
        } else {
          const yesObservations = observationsWithResponseForAdmNodeByValue(observations, node, 'yes');
          percentage = observationCount > 0 ? (yesObservations.length / observationCount) * 100 : 0;
          percentage = Math.round(percentage);
        }

        data[labelIndex] = percentage;
      });

      datasets.push({
        label: `${survey.name} - ${node.name}`,
        data: data,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
      });
    });
  });

  // Custom legend labels
  const customLegendLabels = selectedNodes.map((node, index) => {
    const colorKey = colorKeys[index % colorKeys.length];
    return {
      text: node.name,
      fillStyle: COLORS[colorKey],
      strokeStyle: COLORS[colorKey],
      lineWidth: 0,
      fontColor: '#333',
    };
  });

  return {
    title: 'Indicators',
    labels: labels,
    datasets: datasets,
    options: {
      plugins: {
        legend: {
          display: true,
          labels: {
            generateLabels: () => customLegendLabels,
          },
        },
      },
    },
  };
};

const getIndicatorsBarChartConfig = (surveys, selectedNodes, is5PointScaleComparison) => {
  const labels = selectedNodes.map((node) => node.name);
  const colorKeys = Object.keys(COLORS);

  let datasets = surveys.map((survey, surveyIndex) => {
    const data = selectedNodes.map((node, nodeIndex) => {
      const relevantObservations = observationsWithResponseForAdmNode(survey.observations, node);
      const observationCount = relevantObservations.length;
      let result;

      if (isNietSurveyType(survey) || is5PointScaleComparison) {
        const sumResponses = sumResponsesByAdmNode(survey.observations, node);
        const averageValue = observationCount > 0 ? sumResponses / observationCount : 0;
        return averageValue.toFixed(1);
      } else {
        const yesObservations = observationsWithResponseForAdmNodeByValue(survey.observations, node, 'yes');
        result = observationCount > 0 ? (yesObservations.length / observationCount) * 100 : 0;
        return Math.round(result);
      }
    });

    const backgroundColors = selectedNodes.map((node, nodeIndex) => {
      const colorIndex = nodeIndex % colorKeys.length;
      const colorKey = colorKeys[colorIndex];
      return COLORS[colorKey];
    });

    return {
      label: survey.name,
      data: data,
      backgroundColor: backgroundColors,
      // comment barThickness and maxBarThickness to align the bars next to each other
      // and enable the barPercentage and categoryPercentage options below
      // barPercentage: 0.9,
      // categoryPercentage: 1,
    };
  });

  const customLegendLabels = selectedNodes.map((node, index) => {
    const colorKey = colorKeys[index % colorKeys.length];
    return {
      text: node.name,
      fillStyle: COLORS[colorKey],
      strokeStyle: COLORS[colorKey],
      lineWidth: 0,
      fontColor: '#333',
    };
  });

  return {
    title: 'Indicators',
    labels: labels,
    datasets: datasets,
    options: {
      plugins: {
        legend: {
          display: true,
          labels: {
            generateLabels: (chart) => customLegendLabels,
          },
        },
      },
    },
  };
};

const getIndicatorsLineChartConfig = (surveys, selectedNodes, isPointScaleComparison) => {
  const labels = surveys.map((survey) => survey.name);
  const colorKeys = Object.keys(COLORS);

  let datasets = selectedNodes.map((node, nodeIndex) => {
    const colorKey = colorKeys[nodeIndex % colorKeys.length];
    const color = COLORS[colorKey];

    // Calculate percentage for each survey for the node
    const data = surveys.map((survey) => {
      const relevantObservations = observationsWithResponseForAdmNode(survey.observations, node);
      const observationCount = relevantObservations.length;
      let percentage;

      if (isNietSurveyType(survey) || isPointScaleComparison) {
        const sumResponses = sumResponsesByAdmNode(relevantObservations, node);
        const averageValue = observationCount > 0 ? sumResponses / observationCount : 0;
        return averageValue.toFixed(1);
      } else {
        const yesObservations = observationsWithResponseForAdmNodeByValue(relevantObservations, node, 'yes');
        percentage = observationCount > 0 ? (yesObservations.length / observationCount) * 100 : 0;
        return Math.round(percentage);
      }
    });

    return {
      label: node.name,
      data: data,
      borderColor: color,
      backgroundColor: color,
      pointBorderColor: color,
      pointBackgroundColor: color,
      pointBorderWidth: 0,
    };
  });

  return {
    title: 'Indicators',
    labels: labels,
    datasets: datasets,
    options: {
      plugins: {
        legend: {
          display: true,
        },
      },
    },
  };
};

const getGroupedIndicatorsLineChartConfig = (surveys, selectedNodes, groupBy, isPointScaleComparison) => {
  const labels = surveys.map((survey) => survey.name);
  const colorKeys = Object.keys(COLORS);

  let datasets = [];

  selectedNodes.forEach((node, nodeIndex) => {
    const nodeColorKey = colorKeys[nodeIndex % colorKeys.length];
    const nodeColor = COLORS[nodeColorKey];

    // Aggregate surveys by the groupBy criterion
    surveys.forEach((survey, surveyIndex) => {
      const groupedObservations = groupObservationsBy(groupBy, survey.observations);
      Object.keys(groupedObservations)
        .sort()
        .forEach((groupName) => {
          const observations = groupedObservations[groupName];
          const relevantObservations = observationsWithResponseForAdmNode(observations, node);
          const observationCount = relevantObservations.length;
          let percentage;

          if (isNietSurveyType(survey) || isPointScaleComparison) {
            const sumResponses = sumResponsesByAdmNode(relevantObservations, node);
            const averageValue = observationCount > 0 ? sumResponses / observationCount : 0;
            percentage = averageValue.toFixed(1);
          } else {
            const yesObservations = observationsWithResponseForAdmNodeByValue(relevantObservations, node, 'yes');
            percentage = observationCount > 0 ? (yesObservations.length / observationCount) * 100 : 0;
            percentage = Math.round(percentage);
          }

          // Initialize the dataset for this group/node combination if it doesn't exist
          let dataset = datasets.find((d) => d.label === `${node.name} - ${groupName}`);
          if (!dataset) {
            dataset = {
              label: `${node.name} - ${groupName}`,
              data: new Array(surveys.length).fill(null),
              borderColor: nodeColor,
              backgroundColor: nodeColor,
              pointBorderColor: nodeColor,
              pointBackgroundColor: nodeColor,
            };
            datasets.push(dataset);
          }

          // Assign the calculated percentage to the correct position for this survey
          dataset.data[surveyIndex] = percentage;
        });
    });
  });

  // Custom legend labels
  const customLegendLabels = selectedNodes.map((node, index) => {
    const colorKey = colorKeys[index % colorKeys.length];
    return {
      text: node.name,
      fillStyle: COLORS[colorKey],
      strokeStyle: COLORS[colorKey],
      lineWidth: 0,
      fontColor: '#333',
    };
  });

  return {
    title: 'Grouped Indicators',
    labels: labels,
    datasets: datasets,
    options: {
      plugins: {
        legend: {
          display: true,
          labels: {
            generateLabels: () => customLegendLabels,
          },
        },
      },
    },
  };
};

export const getMetaDataChartConfig = (surveys, metric, chartType, groupBy = 'default') => {
  if (isGrouped(groupBy)) {
    return chartType === CHART_TYPES.BAR ? getGroupedBarChartConfig(surveys, metric, groupBy) : getGroupedLineChartConfig(surveys, metric, groupBy);
  } else {
    return chartType === CHART_TYPES.BAR ? getBarChartConfig(surveys, metric) : getLineChartConfig(surveys, metric);
  }
};

export const getAdmTreeChartConfig = (surveys, selectedNodes, chartType, groupBy, isPointScaleComparison = false) => {
  if (isGrouped(groupBy)) {
    return chartType === CHART_TYPES.BAR
      ? getGroupedIndicatorsBarChartConfig(surveys, selectedNodes, groupBy, isPointScaleComparison)
      : getGroupedIndicatorsLineChartConfig(surveys, selectedNodes, groupBy, isPointScaleComparison);
  } else {
    return chartType === CHART_TYPES.BAR
      ? getIndicatorsBarChartConfig(surveys, selectedNodes, isPointScaleComparison)
      : getIndicatorsLineChartConfig(surveys, selectedNodes, isPointScaleComparison);
  }
};
