import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Grid,
  Select,
  TextField,
  Typography,
  FormGroup,
  FormControl,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
  Button,
  Autocomplete,
} from '@mui/material';
import { createObservable, updateObservable } from '../../../apis/care/observables/api';
import { useConfirmDialog } from '../../modals/confirm_dialog';
import _ from 'lodash';
import FormHelperText from '@mui/material/FormHelperText';
import { createFilterOptions } from '@mui/material/Autocomplete';

const ObservableForm = ({
  observable,
  observable_label,
  observable_types,
  org_users,
  assignable_users,
  race_ethnicity_options,
  gender_options,
  base_url,
  afterSave,
  onClose,
  confirmCancelRef,
}) => {
  const getTypeFromId = (typeId) => {
    return observable_types.find((type) => type.id === typeId);
  };

  const [typeId, setTypeId] = useState(() => {
    if (observable?.observable_type?.id) {
      return observable.observable_type.id;
    } else if (observable_types.length === 1) {
      return observable_types[0].id;
    } else {
      return '';
    }
  });
  const [uniqueId, setUniqueId] = useState(typeId ? getTypeFromId(typeId)?.unique_id : '');
  const [name, setName] = useState(observable?.name || '');
  const [description, setDescription] = useState(observable?.description || '');
  const [email, setEmail] = useState(observable?.email || '');
  const [available, setAvailable] = useState(observable?.available ?? true);
  const [gender, setGender] = useState(observable?.gender || '');
  const [raceEthnicity, setRaceEthnicity] = useState(observable?.race_ethnicity || '');
  const [tenureWithOrganization, setTenureWithOrganization] = useState(observable?.tenure_with_organization ?? null);

  const [linkedUser, setLinkedUser] = useState(() => {
    if (observable?.observable_entity?.id) {
      return org_users.find((user) => user.email === observable.observable_entity.email) || '';
    } else {
      return '';
    }
  });

  // Merge assignable_users with the current linked user if it exists
  const extendedAssignableUsers = [...assignable_users];
  if (linkedUser && !assignable_users.some((user) => user.id === linkedUser.id)) {
    extendedAssignableUsers.push(linkedUser);
  }

  extendedAssignableUsers.sort((a, b) => a.full_name.localeCompare(b.full_name));

  const [errors, setErrors] = useState({});

  const initialValuesRef = useRef({
    typeId,
    name,
    description,
    email,
    available,
    linkedUser,
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  useEffect(() => {
    const initialValues = initialValuesRef.current;

    if (
      typeId !== initialValues.typeId ||
      name !== initialValues.name ||
      description !== initialValues.description ||
      email !== initialValues.email ||
      available !== initialValues.available ||
      linkedUser?.id !== initialValues.linkedUser?.id
    ) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [typeId, name, description, email, available, linkedUser]);

  const handleSave = async (e) => {
    e.preventDefault();
    const observableData = {
      name,
      description,
      email,
      gender,
      available,
      race_ethnicity: raceEthnicity,
      tenure_with_organization: tenureWithOrganization,
      observable_type_id: typeId,
      observable_entity_id: linkedUser?.id || null,
      observable_entity_type: linkedUser ? 'User' : null,
    };

    try {
      let response;
      if (observable) {
        response = await updateObservable(
          {
            ...observableData,
            id: observable.id,
          },
          base_url
        );
      } else {
        response = await createObservable(observableData, base_url);
      }

      setErrors({});
      afterSave(response);
    } catch (error) {
      setErrors(error);
    }
  };

  const confirmCancel = () => {
    if (hasUnsavedChanges) {
      showConfirmDialog({
        title: 'Unsaved Changes',
        description: 'You have unsaved changes. Are you sure you want to discard them?',
        actions: [
          {
            label: 'Discard Changes',
            variant: 'text',
            color: 'primary',
            autoFocus: false,
            onClick: async () => {
              onClose();
              hideConfirmDialog();
            },
          },
          {
            label: 'Keep Editing',
            variant: 'contained',
            color: 'primary',
            onClick: () => {
              hideConfirmDialog();
            },
          },
        ],
      });
    } else {
      onClose();
    }
  };

  // Assign confirmCancel to confirmCancelRef
  useEffect(() => {
    if (confirmCancelRef) {
      confirmCancelRef.current = confirmCancel;
    }
  }, [confirmCancelRef, hasUnsavedChanges]);

  const handleTypeChange = (e) => {
    setTypeId(e.target.value);
    const selectedType = getTypeFromId(e.target.value);
    setUniqueId(selectedType ? selectedType.unique_id : '');
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => (option.full_name ? option.full_name.toLowerCase() : '') + ' ' + (option.email ? option.email.toLowerCase() : ''),
  });

  const hasErrors = (prop) => errors && errors[prop]?.length > 0;

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box sx={{ overflowY: 'auto', flexGrow: 1, padding: 4 }}>
        <Typography variant="h5">{observable ? 'Edit' : 'Add'} Observee</Typography>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth required error={hasErrors('observable_type_id')}>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select labelId="type-select-label" id="type" value={typeId} label="Type" onChange={handleTypeChange}>
                {observable_types.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {hasErrors('observable_type_id') && <FormHelperText>{errors.observable_type_id}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={extendedAssignableUsers}
              filterOptions={filterOptions}
              value={linkedUser || null}
              autoHighlight
              onChange={(event, newValue) => {
                if (newValue) {
                  setLinkedUser(newValue);
                  setName(newValue.full_name);
                  setEmail(newValue.email);
                } else {
                  setLinkedUser('');
                  setName('');
                  setEmail('');
                }
              }}
              getOptionLabel={(option) => option.full_name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <div>{option.full_name}</div>
                    <Typography variant="body2" color="textSecondary">
                      {option.email}
                    </Typography>
                  </Stack>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Linked User"
                  size="small"
                  error={hasErrors('observable_entity_id')}
                  helperText={errors.observable_entity_id}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={errors.name?.length > 0}
              helperText={errors.name}
              size="small"
              variant="outlined"
              fullWidth
              required
              disabled={linkedUser !== ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={errors.description?.length > 0}
              helperText={errors.description}
              size="small"
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errors.email?.length > 0}
              helperText={errors.email}
              required={uniqueId === 'email'}
              disabled={linkedUser !== ''}
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select labelId="gender-select-label" id="gender-select" value={gender} label="Gender" onChange={(e) => setGender(e.target.value)}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {gender_options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {_.capitalize(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="race-ethnicity-select-label">Race/Ethnicity</InputLabel>
              <Select
                labelId="race-ethnicity-select-label"
                id="race-ethnicity-select"
                value={raceEthnicity}
                label="Race/Ethnicity"
                onChange={(e) => setRaceEthnicity(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {race_ethnicity_options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {_.startCase(_.toLower(option))
                      .replaceAll('Not Hispanic Or Latino', '(Not Hispanic or Latino)')
                      .replaceAll('All Other Races', '(All Other Races)')
                      .replaceAll('Or', 'or')
                      .replaceAll('Not', 'not')
                      .replaceAll('To', 'to')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Tenure with organization"
              name="tenure_with_organization"
              error={errors.tenure_with_organization?.length > 0}
              helperText={errors.tenure_with_organization}
              value={tenureWithOrganization !== null ? tenureWithOrganization : ''}
              onChange={(e) => setTenureWithOrganization(e.target.value)}
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel control={<Switch checked={available} onChange={(e) => setAvailable(e.target.checked)} />} label="Available" />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', padding: 2, paddingLeft: 4, boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)' }}>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={handleSave} sx={{ minWidth: '150px' }}>
            Save
          </Button>
          <Button onClick={confirmCancel}>Cancel</Button>
        </Stack>
      </Box>
    </Box>
  );
};

ObservableForm.propTypes = {
  observable: PropTypes.object,
};

export default ObservableForm;
