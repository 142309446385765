import React, { useEffect, useState } from 'react';
import { Button, FormControl, TextField, Stack, Typography, Select, MenuItem, FormHelperText, InputLabel } from '@mui/material';
import { createDataModel, updateDataModel } from '../../../apis/care/data_models/api';

export default function DataModelForm({ node, onClose, afterSave, ...props }) {
  const [name, setName] = useState(node?.name || '');
  const [description, setDescription] = useState(node?.description || '');
  const [analysisNodeType, setAnalysisNodeType] = useState(node?.dashboard_analysis_node_type || '');
  const [errors, setErrors] = useState({});
  const isEditing = !!node;

  const handleSave = async (e) => {
    let response;
    if (isEditing) {
      response = await updateDataModel({ name, description, dashboard_analysis_node_type: analysisNodeType, id: node.id }, `data_models`);
    } else {
      response = await createDataModel({ name, description, dashboard_analysis_node_type: analysisNodeType }, 'data_models');
    }
    if (response.errors) {
      setErrors(response.errors);
    } else {
      setErrors({});
      afterSave(response);
    }
  };

  const hasErrors = (prop) => errors && errors[prop]?.length > 0;

  return (
    <>
      <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h6"> {node ? `Edit ${node.name}` : 'New Data Model'} </Typography>
          <TextField
            required
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={hasErrors('name')}
            helperText={errors['name']}
          />
          <TextField label="Description" variant="outlined" multiline value={description} onChange={(e) => setDescription(e.target.value)} />
          <FormControl fullWidth required error={hasErrors('dashboard_analysis_node_type')}>
            <InputLabel id="dashboard-analysis-node-type-select-label">Dashboard Analysis Node Type</InputLabel>
            <Select
              labelId="dashboard-analysis-node-type-select-label"
              id="dashboard-analysis-node-type-select"
              label="Dashboard Analysis Node Type"
              value={analysisNodeType}
              onChange={(e) => setAnalysisNodeType(e.target.value)}
            >
              <MenuItem value="Care::Aspect">Aspect</MenuItem>
              <MenuItem value="Care::SummaryIndicator">Summary Indicator</MenuItem>
              <MenuItem value="Care::Indicator">Indicator</MenuItem>
            </Select>
            {errors['dashboard_analysis_node_type'] && <FormHelperText>{errors['dashboard_analysis_node_type']}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
