import { useState } from 'react';

export const useSearchableFilter = (items, filterProperty, selectedItems) => {
  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredItems = items.filter((item) => selectedItems.has(item.id) || item[filterProperty].toLowerCase().includes(searchText.toLowerCase()));

  return {
    searchText,
    handleSearchChange,
    filteredItems,
  };
};
