import React, { useEffect, useState } from 'react';
import { Button, Dialog, Stack, Typography, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { closeButtonSx } from '../../../../constants/dialog';
import { useDelegateDialog } from './delegate_dialog_context';
import ConfirmContent from './confirm_content';
import FormContent from './form_content';
import { buildTreeFromOrganizations, getNumberOfCheckedItems } from '../helpers';
import { useCheckboxHandlers } from '../use_checkbox_handlers';
import { useContentStage } from '../use_content_stage';
import { upperFirst, camelCase, startCase } from 'lodash';
import { trackCzEvent } from '../../../../helpers/track_cz_event';

const DelegateDialog = () => {
  const { open, originElement, targetOrgs, closeDelegateDialog, saveData } = useDelegateDialog();
  const [orgTree, setOrgTree] = useState([]);
  const [checkedState, setCheckedState] = useState(new Map());
  const [sendEmailConfirmation, setSendEmailConfirmation] = useState(true);

  const { handleCheckChange, handleSelectNone, handleSelectAll } = useCheckboxHandlers(setCheckedState);
  const { handleContentStageChange, isFormStage, isConfirmationStage } = useContentStage();

  useEffect(() => {
    if (open) {
      // Initialize treeData
      const hierarchicalData = buildTreeFromOrganizations(targetOrgs);
      setOrgTree(hierarchicalData);

      // Initialize checked states
      let initialState = new Map();
      targetOrgs?.forEach((o) => {
        initialState.set(o.id, o.is_delegated_to_org);
      });
      handleContentStageChange('form');
      setCheckedState(initialState);
    }
  }, [open, targetOrgs]);

  const handleDelegate = () => {
    const newDelegationPlanIds = targetOrgs.filter((o) => checkedState.get(o.id) && !o.is_delegated_to_org).map((plan) => plan.id);
    const unDelegationPlanIds = targetOrgs.filter((o) => !checkedState.get(o.id) && o.is_delegated_to_org).map((plan) => plan.id);
    const delegationsParams = {
      delegations: {
        care_survey_id: originElement.id,
        send_email_confirmation: sendEmailConfirmation,
        new_delegation_target_org_ids: newDelegationPlanIds,
        un_delegation_target_org_ids: unDelegationPlanIds,
        origin_elements: [originElement.id],
      },
    };

    saveData(delegationsParams);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    closeDelegateDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth={'sm'}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{ paper: 'delegate-dialog' }}
      fullWidth={true}
      disableEscapeKeyDown
    >
      <DialogTitle id="scroll-dialog-title">
        Delegate {originElement.displayTitle}
        <Typography
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {originElement.description}
        </Typography>
      </DialogTitle>
      <IconButton aria-label="close" onClick={closeDelegateDialog} sx={closeButtonSx}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers={true} sx={{ height: '35vh', display: 'flex', flexDirection: 'column' }}>
        <div id="scroll-dialog-description" tabIndex={-1} style={{ flexGrow: 1 }}>
          {isFormStage() && (
            <FormContent
              orgTree={orgTree}
              checkedState={checkedState}
              handleCheckChange={handleCheckChange}
              handleSelectAll={handleSelectAll}
              handleSelectNone={handleSelectNone}
            />
          )}
          {isConfirmationStage() && (
            <ConfirmContent elementTitle={originElement.displayTitle} numberOfCheckedItems={getNumberOfCheckedItems(checkedState)} />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
          {isConfirmationStage() ? (
            <Button variant="contained" color="secondary" onClick={() => handleContentStageChange('form')}>
              Back
            </Button>
          ) : (
            <div></div>
          )}
          <Stack direction="row" spacing={2}>
            <Button onClick={() => closeDelegateDialog()}>Cancel</Button>
            {isFormStage() ? (
              <Button variant="contained" onClick={() => handleContentStageChange('confirmation')}>
                Confirm
              </Button>
            ) : (
              <Button variant="contained" onClick={handleDelegate}>
                Delegate
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default DelegateDialog;
