import React from 'react';
import Grid from '@mui/material/Grid';

export default ({ ...props }) => {
  const { edited, created_by, last_edited_by, updated_at, created_at } = props.survey;

  return (
    <Grid container>
      <Grid item xs={6}>
        <span className="me-2">Created by</span>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'right' }}>
        {created_by?.full_name || ''}
      </Grid>
      <Grid item xs={6} sx={{ marginTop: 0.5 }}>
        <span className="me-2">Created on</span>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: 0.5, textAlign: 'right' }}>
        {new Date(created_at).toLocaleDateString({}, { timeZone: 'UTC' })}
      </Grid>
      {edited && (
        <>
          <Grid item xs={6} sx={{ marginTop: 0.5 }}>
            <span className="me-2">Edited by</span>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 0.5, textAlign: 'right' }}>
            {last_edited_by?.full_name || ''}
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 0.5 }}>
            <span className="me-2">Edited on</span>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: 0.5, textAlign: 'right' }}>
            {new Date(updated_at).toLocaleDateString({}, { timeZone: 'UTC' })}
          </Grid>
        </>
      )}
      <Grid item xs={6} sx={{ marginTop: 0.5 }}>
        <span className="me-2">Observations</span>
      </Grid>
      <Grid item xs={6} sx={{ marginTop: 0.5, textAlign: 'right' }}>
        {props.observationCount}
      </Grid>
    </Grid>
  );
};
