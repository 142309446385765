import React from 'react';
import { faBoxArchive, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import ActionLink from './action_link';

export default ({ isDelegated, ...props }) => {
  const { edited } = props.survey;
  const verb = edited ? 'Edited' : 'Created';

  return (
    <div className="mt-3">
      {!props.survey.archived && (
        <div>
          <ActionLink icon={faBoxArchive} onClick={() => props.onSave({ archived_at: new Date(), status: 'disabled' }, 'archiving')} text="Archive" />
        </div>
      )}
      {props.survey.archived && (
        <div>
          <ActionLink icon={faBoxArchive} onClick={() => props.onSave({ archived_at: null }, 'unarchiving')} text="Unarchive" />
        </div>
      )}
      {!isDelegated && (
        <div>
          <ActionLink icon={faTrashCan} onClick={() => props.onDelete(props.survey.id)} text="Delete" />
        </div>
      )}
    </div>
  );
};
