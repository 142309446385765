import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SummaryBox = styled(Paper)(({ theme }) => ({
  height: '160px !important',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const SummaryEmptyStateIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: '2em',
  color: theme.palette.grey[300],
}));
