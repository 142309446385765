import React, { useMemo, useState } from 'react';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import usePersistColumnSizes from '../../../helpers/data_grid/use_persisted_column_sizes';
import ResetColumnSizesMenu from '../../../helpers/data_grid/reset_column_sizes_menu';
import { commonConfig } from '../../../helpers/data_grid/common_config';
import { gridStyles } from '../../../helpers/data_grid/common_grid_styles';
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';

const ObservablesTable = ({ observables, onEdit, onDelete }) => {
  const theme = useTheme();
  const columns = useMemo(
    () => [
      {
        field: 'observable_type',
        headerName: 'Type',
        type: 'string',
        // For sorting to work, we need to provide a valueGetter that returns the data.
        // Alternatively (when using renderCell), we need a custom "sortComparator" in the column definition.
        valueGetter: (value, row) => row.observable_type?.name || '',
        valueFormatter: (value, row) => row.observable_type?.name || '',
      },
      { field: 'name', headerName: 'Name', flex: 1 },
      {
        field: 'email',
        type: 'string',
        headerName: 'Email',
        flex: 1,
      },
      {
        field: 'description',
        type: 'string',
        headerName: 'Description',
        flex: 1,
      },
      {
        field: 'available',
        type: 'boolean',
        headerName: 'Available',
        flex: 1,
      },
      {
        field: 'observable_entity',
        type: 'string',
        headerName: 'Linked User',
        flex: 1,
        valueFormatter: (value) => value?.name || '',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        sortable: false,
        align: 'right',
        resizable: false,
        width: 90,
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              key={`edit-${id}`}
              icon={<FontAwesomeIcon icon={faPencil} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
              label="Edit"
              onClick={() => onEdit(id)}
            />,
            <GridActionsCellItem
              key={`delete-${id}`}
              icon={<FontAwesomeIcon icon={faTrashCan} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
              label="Delete"
              onClick={() => onDelete(id)}
            />,
          ];
        },
      },
    ],
    [observables]
  );

  const [adjustedColumns, handleColumnResize, resetColumnWidths] = usePersistColumnSizes('ObservablesTable', columns);

  return (
    <>
      <DataGridPro
        rows={observables}
        columns={adjustedColumns}
        onColumnResize={handleColumnResize}
        slots={{
          columnMenu: ResetColumnSizesMenu,
        }}
        slotProps={{
          columnMenu: {
            columnProps: {
              onResetColumnSizes: () => resetColumnWidths(),
            },
          },
        }}
        {...commonConfig({ disableColumnResize: false })}
        disableColumnPinning={true}
        disableColumnSelector={true}
        sx={gridStyles}
      />
    </>
  );
};

export default ObservablesTable;
