import React from 'react';
import IndicatorRow from './indicator_row';
import AdmNodeRow from './adm_node_row';

const SummaryIndicatorRow = ({
  summaryIndicator,
  observations,
  showHqimColumns,
  showContentColumns,
  showSummaryOnly,
  showFractions,
  surveyTypeCategoryName,
  setDrawerAdmNode,
  showAdmNumbers,
}) => {
  return (
    <>
      <AdmNodeRow
        admNode={summaryIndicator}
        observations={observations}
        showHqimColumns={showHqimColumns}
        showContentColumns={showContentColumns}
        showFractions={showFractions}
        showSummaryOnly={showSummaryOnly}
        showAdmNumbers={showAdmNumbers}
        surveyTypeCategoryName={surveyTypeCategoryName}
        setDrawerAdmNode={setDrawerAdmNode}
      />
      {summaryIndicator.indicators.map((indicator) => (
        <IndicatorRow
          key={indicator.id}
          indicator={indicator}
          observations={observations}
          showHqimColumns={showHqimColumns}
          showContentColumns={showContentColumns}
          showSummaryOnly={showSummaryOnly}
          showFractions={showFractions}
          showAdmNumbers={showAdmNumbers}
          surveyTypeCategoryName={surveyTypeCategoryName}
          setDrawerAdmNode={setDrawerAdmNode}
        />
      ))}
    </>
  );
};

export default SummaryIndicatorRow;
