import { getHeaders } from '../../headers';
import { makeQueryString } from '../../make_query_string';

export const createApi = (defaultBaseUrl) => {
  return {
    delete: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    clone: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}/clone_survey`, {
        method: 'POST',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    create: async (survey) => {
      const response = await fetch(`${defaultBaseUrl}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ survey }),
      });

      if (!response.ok) {
        return response.json().then((errors) => {
          throw errors;
        });
      }
      return response.json();
    },
    update: async (id, survey) => {
      const response = await fetch(`${defaultBaseUrl}/${id}`, {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify({ survey }),
      });

      if (!response.ok) {
        return response.json().then((errors) => {
          throw errors;
        });
      }
      return response.json();
    },
  };
};

export const getSurvey = async (id, baseUrl = '/care/surveys', params = {}) => {
  const url = `${baseUrl}/${id}${makeQueryString(params)}`;
  const response = await fetch(`${url}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
