import React from 'react';
import SummaryIndicatorRow from './summary_indicator_row';
import AdmNodeRow from './adm_node_row';

const AspectRow = ({
  aspect,
  observations,
  showHqimColumns,
  showContentColumns,
  showFractions,
  showSummaryOnly,
  setDrawerAdmNode,
  surveyTypeCategoryName,
  showAdmNumbers,
}) => {
  return (
    <>
      <AdmNodeRow
        admNode={aspect}
        observations={observations}
        showHqimColumns={showHqimColumns}
        showContentColumns={showContentColumns}
        showFractions={showFractions}
        showAdmNumbers={showAdmNumbers}
        showSummaryOnly={showSummaryOnly}
        surveyTypeCategoryName={surveyTypeCategoryName}
        setDrawerAdmNode={setDrawerAdmNode}
      />
      {aspect.summary_indicators.map((summaryIndicator) => (
        <SummaryIndicatorRow
          key={summaryIndicator.id}
          summaryIndicator={summaryIndicator}
          observations={observations}
          showHqimColumns={showHqimColumns}
          showContentColumns={showContentColumns}
          showSummaryOnly={showSummaryOnly}
          surveyTypeCategoryName={surveyTypeCategoryName}
          showFractions={showFractions}
          showAdmNumbers={showAdmNumbers}
          setDrawerAdmNode={setDrawerAdmNode}
        />
      ))}
    </>
  );
};

export default AspectRow;
