import React, { useState } from 'react';
import { Button, Checkbox } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import Aspect from './adm_node_selector/aspect';

export default ({ ...props }) => {
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const isIncluded = (admNodeType, id) => {
    return props.selectedNodes[admNodeType].includes(id);
  };

  return (
    <div className="adm-selector">
      {props.admTree.map((aspectCategory, index) => (
        <div key={aspectCategory.id} className="mt-5">
          <div className="d-flex align-items-center justify-content-between gap-2">
            <Stack direction="row" justifyContent="space-between" style={{ width: '100%' }}>
              <h1 className="headline-color">{aspectCategory.name}</h1>
              {aspectCategory.aspects.length == 0 && <Checkbox disabled checked style={{ paddingRight: 42 }} />}
            </Stack>
            <div>
              <Stack direction="row">
                {index == 0 && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={props.isAllChecked ? props.uncheckAll : props.checkAll}
                    style={{ textWrap: 'nowrap' }}
                  >
                    {props.isAllChecked ? 'Uncheck All' : 'Check All'}
                  </Button>
                )}
                {props.isCareDetail && index === 0 && (
                  <a className="link-dark-no-underline d-inline-flex" onClick={() => setIsAllExpanded(!isAllExpanded)}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <span style={{ textWrap: 'nowrap' }}>{isAllExpanded ? 'Collapse All' : 'Expand All'}</span>
                      <ExpandMoreIcon
                        color="#585858"
                        style={{
                          transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                          transform: isAllExpanded ? 'rotate(180deg)' : 'none',
                        }}
                      />
                    </Stack>
                  </a>
                )}
              </Stack>
            </div>
          </div>
          <div>
            {aspectCategory.aspects.map((aspect) => (
              <Aspect key={aspect.id} aspect={aspect} isIncluded={isIncluded} isAllExpanded={isAllExpanded} {...props} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
