import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { useSearchableFilter } from './use_searchable_filter';
import FilterAccordion from './filter_accordion';
import { availableGradeRanges } from './utils';
import { extractUniqueAndSort, extractGradeLevels, extractDiversityOptions, extractObservers } from '../../shared/utils';

const ShowFilters = ({
  surveys,
  observations,
  selectedSchools,
  selectedObservables,
  selectedObservers,
  selectedGradeLevels,
  selectedGradeRanges,
  selectedContentAreas,
  selectedInstructionLanguages,
  selectedDiversityOptions,
  onSchoolFilterChange,
  onObservableFilterChange,
  onObserverFilterChange,
  onGradeLevelFilterChange,
  onGradeRangeFilterChange,
  onContentAreaFilterChange,
  onInstructionLanguageFilterChange,
  onDiversityOptionFilterChange,
  isFilterActive,
  onClearFilters,
  filtersMap,
  onDateRangeFilterChange,
  selectedDateRange,
  gradeLevels,
}) => {
  // prep data
  let schoolNames = extractUniqueAndSort(surveys, observations, 'school_names', 'school_name');
  let contentAreas = extractUniqueAndSort(surveys, observations, 'content_areas', 'content_area');
  let observables = extractUniqueAndSort(surveys, observations, 'observables', 'observable');
  let instructionLanguages = extractUniqueAndSort(surveys, observations, 'instruction_languages', 'instruction_language');
  let myGradeLevels = extractGradeLevels(observations, gradeLevels);
  let diversityOptions = extractDiversityOptions(observations);
  let observers = extractObservers(observations);

  // prep hooks
  const {
    searchText: schoolSearchText,
    handleSearchChange: handleSchoolSearchChange,
    filteredItems: filteredSchoolNames,
  } = useSearchableFilter(schoolNames, 'name', selectedSchools);

  const {
    searchText: observableSearchText,
    handleSearchChange: handleObservableSearchChange,
    filteredItems: filteredObservables,
  } = useSearchableFilter(observables, 'name', selectedObservables);

  const {
    searchText: contentSearchText,
    handleSearchChange: handleContentSearchChange,
    filteredItems: filteredContentAreas,
  } = useSearchableFilter(contentAreas, 'name', selectedContentAreas);

  const {
    searchText: observerSearchText,
    handleSearchChange: handleObserverSearchChange,
    filteredItems: filteredObservers,
  } = useSearchableFilter(observers, 'full_name', selectedObservers);

  const handleSelectAll = (items, changeHandler) => {
    const itemIds = items.map((item) => {
      // Check if the item is an object and has an 'id' property
      return typeof item === 'object' && item.id ? item.id : item;
    });
    changeHandler(itemIds);
  };

  const gradeRanges = availableGradeRanges(myGradeLevels);

  const observableTypes = new Set(observables.map((o) => o.observable_type.name));

  return (
    <>
      <div className="filter-by-header">
        <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Typography variant="body1">
            <strong>Filter by</strong>
          </Typography>
          {isFilterActive && (
            <a className="link-dark-no-underline d-inline-flex" onClick={() => onClearFilters('')}>
              <Typography variant="body2" color="#009A96">
                Clear All
              </Typography>
            </a>
          )}
        </Stack>
      </div>
      {filtersMap?.showSchoolNameFilter && (
        <FilterAccordion
          prop_name="school_name"
          title="School Name"
          items={filteredSchoolNames}
          selectedItems={selectedSchools}
          isSearchable={schoolNames.length > 1}
          searchText={schoolSearchText}
          onSearchChange={handleSchoolSearchChange}
          onFilterChange={onSchoolFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.name}
          sortFn={(item) => item.name}
        />
      )}
      {filtersMap?.showGradeLevelsFilter && (
        <FilterAccordion
          prop_name="grade_level"
          title="Grade"
          items={myGradeLevels}
          selectedItems={selectedGradeLevels}
          isSearchable={false}
          onFilterChange={onGradeLevelFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.name}
          hasTabs={true}
          selectedGradeRanges={selectedGradeRanges}
          gradeRanges={gradeRanges}
          onGradeRangeFilterChange={onGradeRangeFilterChange}
        />
      )}
      {filtersMap?.showContentFilter && (
        <FilterAccordion
          prop_name="content_area"
          title="Content"
          items={filteredContentAreas}
          selectedItems={selectedContentAreas}
          isSearchable={contentAreas.length > 1}
          searchText={contentSearchText}
          onSearchChange={handleContentSearchChange}
          onFilterChange={onContentAreaFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.name}
          sortFn={(item) => item.name}
        />
      )}
      {filtersMap?.showObservablesFilter && (
        <FilterAccordion
          prop_name="observable"
          title={observableTypes.size === 1 ? observableTypes.values().next().value : 'Observee'}
          items={filteredObservables}
          selectedItems={selectedObservables}
          isSearchable={observables.length > 1}
          searchText={observableSearchText}
          onSearchChange={handleObservableSearchChange}
          onFilterChange={onObservableFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.name}
          sortFn={(item) => item.name}
        />
      )}
      {filtersMap?.showInstructionLanguagesFilter && (
        <FilterAccordion
          prop_name="instruction_language"
          title="Instruction Language"
          items={instructionLanguages}
          selectedItems={selectedInstructionLanguages}
          isSearchable={false}
          onFilterChange={onInstructionLanguageFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.name}
          sortFn={(item) => item.name}
        />
      )}
      {filtersMap?.showDiversityFilter && (
        <FilterAccordion
          prop_name="diverse_classroom"
          title="Diversity"
          items={diversityOptions}
          selectedItems={selectedDiversityOptions}
          isSearchable={false}
          onFilterChange={onDiversityOptionFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item}
          sortFn={(item) => item}
        />
      )}
      {filtersMap?.showObserversFilter && (
        <FilterAccordion
          prop_name="observer"
          title="Observer"
          items={filteredObservers}
          selectedItems={selectedObservers}
          isSearchable={observers.length > 1}
          searchText={observerSearchText}
          onSearchChange={handleObserverSearchChange}
          onFilterChange={onObserverFilterChange}
          onSelectAll={handleSelectAll}
          onClearAll={onClearFilters}
          renderLabel={(item) => item.full_name}
          sortFn={(item) => item.full_name}
        />
      )}
      <FilterAccordion
        prop_name="date_range"
        title="Date"
        items={[
          { id: 'all', name: 'All dates' },
          { id: 'last_7_days', name: 'Last 7 days' },
          { id: 'last_30_days', name: 'Last 30 days' },
          { id: 'custom', name: 'Custom Dates' },
        ]}
        selectedItems={new Set([selectedDateRange])}
        isSearchable={false}
        onFilterChange={onDateRangeFilterChange}
        onClearAll={onClearFilters}
        renderLabel={(item) => item.name}
        useRadioButtons={true}
        selectedDateRange={selectedDateRange}
      />
    </>
  );
};

ShowFilters.propTypes = {
  surveys: PropTypes.array.isRequired,
  observations: PropTypes.array.isRequired,
  filtersMap: PropTypes.object.isRequired,
  onSchoolFilterChange: PropTypes.func.isRequired,
  onObservableFilterChange: PropTypes.func.isRequired,
  onObserverFilterChange: PropTypes.func.isRequired,
  onGradeLevelFilterChange: PropTypes.func.isRequired,
  onContentAreaFilterChange: PropTypes.func.isRequired,
  onInstructionLanguageFilterChange: PropTypes.func.isRequired,
  onDiversityOptionFilterChange: PropTypes.func.isRequired,
  selectedSchools: PropTypes.object,
  selectedObservables: PropTypes.object,
  selectedObservers: PropTypes.object,
  selectedGradeLevels: PropTypes.object,
  selectedGradeRanges: PropTypes.object,
  selectedContentAreas: PropTypes.object,
  selectedInstructionLanguages: PropTypes.object,
  selectedDiversityOptions: PropTypes.object,
  isFilterActive: PropTypes.bool,
  onClearFilters: PropTypes.func,
};

export default ShowFilters;
