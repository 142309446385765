import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Stack } from '@mui/material';
import { faChartMixed, faDownload } from '@fortawesome/pro-regular-svg-icons';
import ActionMenuButton from '../../shared/action_menu_button';
import LoadingButton from '../../shared/loading_button';
import DelegatedTag from '../../shared/delegations/survey/delegated_tag';
import DelegatedBadge from '../../shared/delegations/survey/delegated_badge';
import { trackCzEvent } from '../../../helpers/track_cz_event';

const ActiveSurveyItem = ({ survey, onCreateObservation, baseUrlReports, loadingObservationStates, canViewActionMenu = true }) => {
  const menuItems = [
    {
      label: 'Data Analysis',
      icon: faChartMixed,
      onClick: () => (window.location.href = `${baseUrlReports}/${survey.id}${isDelegated ? '?with_delegations=true' : ''}`),
    },
    {
      label: 'Export data',
      icon: faDownload,
      onClick: () => {
        trackCzEvent('CARE:DownloadData', `User downloaded data for template ${survey.id}`, 1);
        window.location.href = `/care/surveys/${survey.id}/export_to_xlsx.xlsx`;
      },
    },
  ];

  const isDelegated = survey['delegated?'];
  const isCreatedByDelegation = survey['created_by_delegation?'];
  const delegatedFromName = survey['delegated_from_org_name'];

  return (
    <TableRow data-cy={`survey-${survey.id}`}>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <div>{survey?.name}</div>
          {isDelegated && <DelegatedTag />}
          {isCreatedByDelegation && <DelegatedBadge tooltipLabel={`Delegated from ${delegatedFromName}`} />}
        </Stack>
      </TableCell>
      <TableCell>{survey?.category_name || '-'}</TableCell>
      <TableCell>{survey?.created_by?.full_name || '-'}</TableCell>
      <TableCell align="center">{survey['hierarchical_observations_count']}</TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <LoadingButton
            isLoading={loadingObservationStates[survey.id] || false}
            onClick={() => onCreateObservation(survey.id)}
            variant="contained"
            color="secondary"
            data-cy="button-create-observation"
            disabled={isDelegated}
          >
            Create Observation
          </LoadingButton>
          {canViewActionMenu && <ActionMenuButton menuItems={menuItems} />}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

ActiveSurveyItem.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    category_name: PropTypes.string.isRequired,
    created_by: PropTypes.object.isRequired,
    observations: PropTypes.array,
    edit_path: PropTypes.string.isRequired,
  }),
  onCreateObservation: PropTypes.func.isRequired,
  baseUrlReports: PropTypes.string.isRequired,
};

export default ActiveSurveyItem;
