import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import CareNav from '../../../components/care/shared/nav';
import OrgSummary from '../../../components/care/coaching_dashboards/org_summary';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Typography, Grid, Link, Box } from '@mui/material';
import { commonConfig } from '../../../helpers/data_grid/common_config';
import { multiLineConfig } from '../../../helpers/data_grid/multi_line_config';
import usePersistColumnSizes from '../../../helpers/data_grid/use_persisted_column_sizes';
import ResetColumnSizesMenu from '../../../helpers/data_grid/reset_column_sizes_menu';
import { gridStyles } from '../../../helpers/data_grid/common_grid_styles';
import { trackCzEvent } from '../../../helpers/track_cz_event';

const ObservablesSection = ({ observables, goalDescriptorsEnabled = true }) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.7,
      renderCell: (value) => <Link href={`${window.location.href}/observables/${value.id}`}>{value.value}</Link>,
    },
    {
      field: 'observation_count',
      headerName: '# Observations',
      flex: 0.6,
    },
    {
      field: 'last_observed',
      headerName: 'Last Observed',
      flex: 0.7,
      valueFormatter: (value) => {
        if (!value) return '';
        return new Date(value).toLocaleDateString({}, { timeZone: 'UTC' });
      },
    },
    {
      field: 'refinement_indicator',
      headerName: 'Indicator',
      flex: 1,
    },
    // if goalDescriptors enabled show the goal descriptors column
    ...(goalDescriptorsEnabled
      ? [
          {
            field: 'refinement_descriptor',
            headerName: 'Refinement Descriptor',
            flex: 0.8,
          },
        ]
      : []),
  ];

  const [adjustedColumns, handleColumnResize, resetColumnWidths] = usePersistColumnSizes('coachDashObservables', columns);

  return (
    <Box sx={{ width: '100%', marginTop: 5 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Teachers
      </Typography>
      <DataGridPro
        rows={observables}
        columns={adjustedColumns}
        onColumnResize={handleColumnResize}
        sx={gridStyles}
        slots={{
          columnMenu: ResetColumnSizesMenu,
        }}
        slotProps={{
          columnMenu: {
            columnProps: {
              onResetColumnSizes: () => resetColumnWidths(),
            },
          },
        }}
        {...commonConfig({ disableColumnResize: false })}
        {...multiLineConfig}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
      />
    </Box>
  );
};

const ObserversSection = ({ observations, goals }) => {
  const groupedObservations = Object.groupBy(observations, (o) => o.observer.id);
  const observerInfo = Object.keys(groupedObservations).map((userId) => {
    const mostRecentObservation = groupedObservations[userId].sort((o2, o1) => {
      const date1 = new Date(o1.observation_date).getTime();
      const date2 = new Date(o2.observation_date).getTime();
      if (date1 > date2) {
        return 1;
      } else if (date1 < date2) {
        return -1;
      } else {
        return 0;
      }
    })[0];

    const groupedGoals = Object.groupBy(goals, (g) => g.coach?.id);

    return {
      id: groupedObservations[userId][0].observer.id,
      name: groupedObservations[userId][0].observer.full_name,
      observation_count: groupedObservations[userId].length,
      latest_observation: mostRecentObservation.observation_date,
      goals_in_progress: groupedGoals[userId]?.length,
    };
  });

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 100,
      renderCell: (value) => <Link href={`${window.location.href}/coaches/${value.id}`}>{value.value}</Link>,
    },
    { field: 'observation_count', headerName: '# Observations', flex: 1 },
    {
      field: 'latest_observation',
      headerName: 'Latest Observation',
      flex: 1,
      valueFormatter: (value) => {
        if (!value) return '';
        return new Date(value).toLocaleDateString({}, { timeZone: 'UTC' });
      },
    },
    { field: 'goals_in_progress', headerName: 'Goals in Progress', flex: 1 },
  ];

  const [adjustedColumns, handleColumnResize, resetColumnWidths] = usePersistColumnSizes('coachDashGoals', columns);

  return (
    <Box sx={{ width: '100%', marginTop: 5 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Observers
      </Typography>
      <DataGridPro
        rows={observerInfo}
        columns={adjustedColumns}
        onColumnResize={handleColumnResize}
        sx={gridStyles}
        slots={{
          columnMenu: ResetColumnSizesMenu,
        }}
        slotProps={{
          columnMenu: {
            columnProps: {
              onResetColumnSizes: () => resetColumnWidths(),
            },
          },
        }}
        {...commonConfig({ disableColumnResize: false })}
        {...multiLineConfig}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
      />
    </Box>
  );
};

const Container = ({ observables, observations, goals, summary_data, ...props }) => {
  useEffect(() => {
    trackCzEvent('CAREView:CoachingDashboard', 'User viewed coaching dashboard', 1);
  }, []);

  return (
    <>
      <CareNav {...props} back_url={''} />
      <Grid container>
        <Grid item md={12}>
          <OrgSummary observations={observations} summaryData={summary_data} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <ObservablesSection observables={observables} goalDescriptorsEnabled={props.goal_descriptors_enabled} />
        </Grid>
        <Grid item xs={12} md={4}>
          <ObserversSection observations={observations} goals={goals} />
        </Grid>
      </Grid>
    </>
  );
};

Container.propTypes = {
  base_url: PropTypes.string.isRequired,
  observables: PropTypes.array,
  observations: PropTypes.array,
  goals: PropTypes.array,
  summary_data: PropTypes.object.isRequired,
  goal_descriptors_enabled: PropTypes.bool,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props}></Container>
      </ThemeProvider>
    </Layout>
  );
};
