import React from 'react';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import Form from '../../../components/care/surveys/form';
import { createApi } from '../../../apis/care/surveys/api';
import useWarnOnUnsavedChanges from '../../../helpers/use_warn_on_unsaved_changes';
import CareNav from '../../../components/care/shared/nav';
import { trackCzEvent } from '../../../helpers/track_cz_event';

const Container = (props) => {
  const api = createApi(props.base_url);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useWarnOnUnsavedChanges();

  const createSurvey = async (survey) => {
    const response = await api.create(survey);
    trackCzEvent('CAREAdd:Template', `User created new template ${response.id}`);
    window.location.href = response.redirect_to;
  };

  return (
    <ThemeProvider theme={themeAttuned}>
      <CareNav {...props} back_url={props.base_url} />
      <Form onSave={createSurvey} onUnsavedChangesChanged={setHasUnsavedChanges} {...props} />
    </ThemeProvider>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
