import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const getSpinnerSize = (buttonSize) => {
  switch (buttonSize) {
    case 'small':
      return 14;
    case 'large':
      return 20;
    default: // medium
      return 16;
  }
};

const LoadingButton = ({ size = 'medium', isLoading, onClick, children, ...props }) => {
  const spinnerSize = getSpinnerSize(size);

  return (
    <Button
      onClick={onClick}
      size={size}
      style={{ whiteSpace: 'nowrap', minWidth: 'max-content' }}
      endIcon={isLoading ? <CircularProgress size={spinnerSize} color="inherit" /> : null}
      {...props}
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
