export const tableContainerStyles = {
  backgroundColor: 'rgba(227, 235, 240, 0.35)',
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  padding: '24px',
};

export const tableStyles = {
  '& .MuiTableRow-root:last-child td': {
    borderBottom: '0 !important',
  },
};

export const headerContainerStyles = {
  background: 'transparent',
  border: 'none',
  padding: '0 24px',
  marginTop: '1rem',
};

export const headerTitleStyles = {
  color: '#333',
  border: 0,
  padding: '0',
  fontWeight: 600,
  position: 'relative',
};

export const headerCellStyles = (width) => ({
  background: '#193962',
  borderRight: '1px solid #fff',
  borderBottom: '0',
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  color: 'white',
  fontSize: '1rem',
  textAlign: 'center',
  marginBottom: '0',
  padding: '8px 4px',
  width: width,
});

export const nodeTitleStyles = {
  maxWidth: '300px',
  padding: '8px',
  color: '#333',
  cursor: 'pointer',
};

export const nodeTitleNumberStyles = {
  fontWeight: 'bold',
};

export const nodeCellStyles = (backgroundColor, width, styles) => ({
  color: '#333',
  borderBottom: '1px solid rgba(227, 235, 240, 0.35)',
  padding: '8px',
  position: 'relative',
  backgroundColor: backgroundColor,
  width: width,
  ...styles,
});
