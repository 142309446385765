import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { ConfirmDialogProvider, useConfirmDialog } from '../../../components/modals/confirm_dialog';
import ObservationsTable from '../../../components/care/observations/table';
import { deleteObservation, getObservation } from '../../../apis/care/observations/api';
import { trackCzEvent } from '../../../helpers/track_cz_event';
import { Typography, Box, IconButton } from '@mui/material';
import ObservationDialogAdapter from '../../../components/care/observations/observation_dialog_adapter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { getSurvey } from '../../../apis/care/surveys/api';

const Container = (props) => {
  const { survey_json, algoliaAppId, algoliaSearchKey, base_url } = props;
  const searchClient = useMemo(() => algoliasearch(algoliaAppId, algoliaSearchKey), [algoliaAppId, algoliaSearchKey]);
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const [survey, setSurvey] = useState(survey_json || null);
  const [activeObservations, setActiveObservations] = useState(survey?.observations || []);
  const [observation, setObservation] = useState(null);
  const [isObservationDialogOpen, setIsObservationDialogOpen] = useState(false);

  // TODO: make DRY
  const refreshSurvey = async () => {
    if (survey?.id) {
      const surveyResponse = await getSurvey(survey.id);
      const updatedObservations = [...surveyResponse.observations];
      setSurvey({ ...surveyResponse, observations: updatedObservations });
    }
  };

  // TODO: make DRY
  const handleEditObservation = async (surveyId, observationId) => {
    try {
      const response = await getObservation(observationId);
      setObservation(response);
      setIsObservationDialogOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  // TODO: make DRY
  const handleDeleteObservation = async (surveyId, observationId) => {
    try {
      await deleteObservation(observationId);
      trackCzEvent('CAREDelete:Observation', `User deleted observation ${observationId}`);
      const observationIdAsNumber = Number(observationId);
      const updatedObservations = [...activeObservations.filter((obs) => obs.id !== observationIdAsNumber)];
      setActiveObservations(updatedObservations);

      await refreshSurvey();
      hideConfirmDialog();
    } catch (error) {
      console.error(error);
    }
  };

  // TODO: make DRY
  const confirmDeleteObservation = (surveyId, observationId) => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: 'Are you sure you want to delete this Observation?',
      actions: [
        {
          label: 'Cancel',
          onClick: () => hideConfirmDialog(),
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Delete',
          onClick: () => handleDeleteObservation(surveyId, observationId),
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'xs',
    });
  };

  // TODO: make DRY
  const handleCloseObservation = async (newObservation) => {
    setIsObservationDialogOpen(false);

    if (newObservation?.id) {
      await refreshSurvey();
      setActiveObservations((prevObservations) => {
        const observationIndex = activeObservations.findIndex((observation) => observation.id === newObservation.id);
        if (observationIndex > -1) {
          const updatedObservations = [...prevObservations];
          updatedObservations[observationIndex] = newObservation;
          return updatedObservations;
        } else {
          return [...prevObservations, newObservation];
        }
      });
    }
  };

  const goals_url = props.page_props.goals.url;
  const action_steps_url = props.page_props.action_steps.url;

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton
          href={base_url}
          aria-label="back"
          sx={{
            color: '#67bbb9',
            marginRight: 1,
            '&:hover': {
              backgroundColor: 'rgba(103, 187, 185, 0.04)',
            },
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '1rem' }} />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: '500' }}>
          {`${survey.name}`}
        </Typography>
      </Box>
      <ObservationsTable
        searchClient={searchClient}
        survey={survey}
        activeObservations={activeObservations}
        onEditObservation={handleEditObservation}
        onDeleteObservation={confirmDeleteObservation}
        key={JSON.stringify(activeObservations)}
        emptyMessage="This template has no observations."
        gradeLevels={props.grade_levels}
        {...props}
      />
      {isObservationDialogOpen && (
        <ObservationDialogAdapter
          survey={survey}
          observation={observation}
          baseUrl={base_url}
          observableGoalsBaseUrl={goals_url}
          actionStepsBaseUrl={action_steps_url}
          onClose={handleCloseObservation}
          leads={props.leads}
          recipients={props.recipients}
        />
      )}
    </>
  );
};

Container.propTypes = {
  survey_json: PropTypes.object.isRequired,
  algoliaAppId: PropTypes.string.isRequired,
  algoliaSearchKey: PropTypes.string.isRequired,
  base_url: PropTypes.string.isRequired,
  page_props: PropTypes.object.isRequired,
  grade_levels: PropTypes.array.isRequired,
  leads: PropTypes.array,
  recipients: PropTypes.array,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props}></Container>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
