import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { ConfirmDialogProvider } from '../../../components/modals/confirm_dialog';
import AdminCategorySection from '../../../components/care/surveys/admin_category_section';
import CareNav from '../../../components/care/shared/nav';
import { SurveyDelegateDialogProvider } from '../../../components/shared/delegations/survey/delegate_dialog_context';

const Container = (props) => {
  return (
    <>
      <CareNav {...props} back_url={''} />
      {props.survey_type_categories.map((stc) => (
        <div key={stc.id} className="mt-4">
          <AdminCategorySection
            baseUrl={props.base_url}
            baseUrlReports={props.base_url_reports}
            canCreate={true}
            surveyTypeCategory={stc}
            canDelegate={props.can_delegate}
            org_id={props.org_id}
          />
        </div>
      ))}
      <AdminCategorySection
        baseUrl={props.base_url}
        baseUrlReports={props.base_url_reports}
        canCreate={false}
        surveyTypeCategory={{ name: 'Archive', surveys: props.archived_surveys }}
      />
    </>
  );
};

Container.propTypes = {
  base_url: PropTypes.string.isRequired,
  survey_type_categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  permission_props: PropTypes.object.isRequired,
  can_delegate: PropTypes.bool.isRequired,
  page_props: PropTypes.object.isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <SurveyDelegateDialogProvider>
            <Container {...props}></Container>
          </SurveyDelegateDialogProvider>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
