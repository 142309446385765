import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItemText,
  Divider,
  ListItemButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NAV_TYPES } from './utils';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const commonStyles = {
  divider: { borderColor: '#DDD' },
  listItemText: { color: '#112D56', fontWeight: 400 },
};

const accordionStyles = {
  root: {
    '&:before': { display: 'none' },
  },
  details: { paddingTop: 0, paddingBottom: 0 },
};

const Navigation = ({ admTrees, selectedNav, selectedNodes, onNavigationChange, showHqim, hideClassRoomEngagement }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleItemClick = (item) => {
    onNavigationChange(item);
    setExpanded(false);
  };

  const handleCheckChange = (node, isChecked) => {
    const nodeData = { id: node.id, name: node.name, number: node.number, type: node.type };
    let newSelectedNodes = [...selectedNodes];

    if (isChecked) {
      newSelectedNodes = [...selectedNodes, nodeData];
    } else {
      newSelectedNodes = newSelectedNodes.filter((n) => n.id !== node.id || n.name !== node.name || n.type !== node.type);
    }

    onNavigationChange(NAV_TYPES.INDICATORS, newSelectedNodes);
  };

  const renderCheckbox = (node) => {
    if (!node.survey_config) {
      return null;
    }
    const checkedCriteria = (n) => n.id === node.id && n.name === node.name && n.type === node.type;
    const isChecked = selectedNodes.some(checkedCriteria);
    const labelStyle = { fontWeight: '400', color: '#0C2C57' };

    return (
      <ListItem>
        {node.survey_config.question_type !== 'title' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => handleCheckChange(node, e.target.checked)}
                sx={{ color: 'rgba(12, 44, 87, 0.5)' }}
                style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 7 }}
              />
            }
            label={
              <Typography variant="body2" style={labelStyle}>
                {/*{node.number} {node.name}*/}
                {node.name}
              </Typography>
            }
            labelPlacement="end"
            sx={{ alignItems: 'flex-start', width: '100%' }}
          />
        )}
        {node.survey_config.question_type === 'title' && (
          <Typography variant="body2" style={labelStyle}>
            {/*{node.number} {node.name}*/}
            {node.name}
          </Typography>
        )}
      </ListItem>
    );
  };

  const renderIndicator = (indicator, key, depth) => (
    <Grid item xs={12} key={key} sx={{ marginLeft: depth * 1 }}>
      {renderCheckbox(indicator)}
    </Grid>
  );

  const renderSummaryIndicator = (summaryIndicator, key, depth) => (
    <Grid item xs={12} key={key} sx={{ marginLeft: depth * 2 }}>
      {renderCheckbox(summaryIndicator)}
      {summaryIndicator.indicators.map((indicator, i_index) => renderIndicator(indicator, i_index, depth + 2))}
    </Grid>
  );

  const renderAspect = (aspect, key, depth) => (
    <Grid item xs={12} key={key} sx={{ marginLeft: depth * 2 }}>
      {renderCheckbox(aspect)}
      {aspect.summary_indicators.map((summaryIndicator, si_index) => renderSummaryIndicator(summaryIndicator, si_index, depth + 1))}
    </Grid>
  );

  const renderAspectCategory = (aspectCategory, key, depth) => {
    return (
      <List sx={{ width: '100%', padding: 0 }} key={key}>
        {aspectCategory.aspects.map((aspect, a_index) => renderAspect(aspect, a_index, depth))}
      </List>
    );
  };

  const isSelected = (navName) => selectedNav === navName;

  return (
    <>
      <Box sx={{ border: 0, boxShadow: 1, bgcolor: 'white' }}>
        <List component="nav" aria-label="sidebar menu" sx={{ padding: 0 }}>
          <ListItem sx={{ bgcolor: '#F5F8FA', py: 2 }}>
            <ListItemText primary="Metrics" primaryTypographyProps={{ fontWeight: 600, color: '#0C2C57', textAlign: 'left' }} />
          </ListItem>
          {showHqim && (
            <>
              <Divider sx={commonStyles.divider} />
              <ListItemButton selected={isSelected(NAV_TYPES.HQIM_IN_USE)} onClick={() => handleItemClick(NAV_TYPES.HQIM_IN_USE)}>
                <ListItemText primary="HQIM In Use" primaryTypographyProps={commonStyles.listItemText} />
              </ListItemButton>
            </>
          )}

          {!hideClassRoomEngagement && (
            <>
              <Divider sx={commonStyles.divider} />
              <ListItemButton selected={isSelected(NAV_TYPES.STUDENTS_ENG_IN_CLASS)} onClick={() => handleItemClick(NAV_TYPES.STUDENTS_ENG_IN_CLASS)}>
                <ListItemText primary="Classroom Engagement" primaryTypographyProps={commonStyles.listItemText} />
              </ListItemButton>
            </>
          )}

          <Divider sx={commonStyles.divider} />
          <Accordion expanded={expanded} onChange={handleAccordionChange} elevation={0} sx={accordionStyles.root} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#67bbb9' }} />} aria-controls="indicators-content" id="indicators-header">
              <Typography sx={commonStyles.listItemText}>Indicators</Typography>
            </AccordionSummary>
            <AccordionDetails sx={accordionStyles.details}>
              <Grid container spacing={1} paddingTop={2} paddingBottom={2}>
                {admTrees.map((aspectCategory, ac_index) => renderAspectCategory(aspectCategory, ac_index, 0))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </List>
      </Box>
    </>
  );
};

Navigation.propTypes = {
  admTrees: PropTypes.array,
  selectedNav: PropTypes.string,
  onNavigationChange: PropTypes.func.isRequired,
  selectedNodes: PropTypes.array,
};

export default Navigation;
