import React from 'react';
import { Avatar, Tooltip } from '@mui/material';

const sx = {
  width: 16,
  height: 16,
  bgcolor: '#488281',
  fontSize: '11px',
  opacity: '0.7',
};

const letterStyle = {
  marginLeft: '1px',
  marginTop: '1px',
};

const DelegatedBadge = ({ tooltipLabel }) => {
  return (
    <Tooltip enterDelay={200} arrow={false} title={tooltipLabel}>
      <Avatar sx={sx}>
        <span style={letterStyle}>D</span>
      </Avatar>
    </Tooltip>
  );
};

export default DelegatedBadge;
