import { isPointScaleQuestion } from '../../shared/utils';

export const COLORS = {
  cyan: 'rgb(34, 164, 220)',
  purple: 'rgb(115, 61, 204)',
  teal: 'rgb(104, 220, 220)',
  magenta: 'rgb(204, 61, 181)',
  blue: 'rgb(41, 77, 204)',
  tan: 'rgb(178, 162, 143)',
};

export const MIN_OPACITY = 0.5;

export const CHART_TYPES = {
  BAR: 'bar',
  LINE: 'line',
};

export const NAV_TYPES = {
  HQIM_IN_USE: 'hqim_in_use',
  STUDENTS_ENG_IN_CLASS: 'students_engaged_in_class',
  INDICATORS: 'indicators',
};

/**
 * Get color with applied opacity
 * @param color
 * @param opacity
 * @returns {`rgba(${*}, ${*}, ${*}, ${string})`}
 */
export const applyOpacity = (color, opacity) => {
  const [r, g, b] = color.match(/\d+/g);
  return `rgba(${r}, ${g}, ${b}, ${opacity.toFixed(2)})`;
};

/**
 * Merges ADM trees into one tree (taking the intersection of their ADM nodes).
 * - Each ADM tree starts with the Aspect Category node.
 * - Each node is given a new property "checked" which is used to determine if the node is selected.
 * @param trees
 * @returns {any[]}
 */
export const mergeAdmTrees = (trees) => {
  const mergeUniqueByName = (items) => {
    const uniqueItems = new Map();

    items.forEach((item) => {
      const uniqueKey = `${item.id}-${item.name}-${item.type}`;
      if (!uniqueItems.has(uniqueKey)) {
        uniqueItems.set(uniqueKey, { ...item, checked: true });
      }
      if (item.aspects) {
        uniqueItems.get(uniqueKey).aspects = mergeUniqueByName(item.aspects);
      }
      if (item.summary_indicators) {
        uniqueItems.get(uniqueKey).summary_indicators = mergeUniqueByName(item.summary_indicators);
      }
      if (item.indicators) {
        uniqueItems.get(uniqueKey).indicators = mergeUniqueByName(item.indicators);
      }
    });

    return Array.from(uniqueItems.values());
  };

  return mergeUniqueByName(trees.flat());
};

/**
 * Merges ADM trees into one tree (taking the union of their ADM nodes).
 * - Each ADM tree starts with the Aspect Category node.
 * - Each node is given a new property "checked" which is used to determine if the node is selected.
 * @param trees
 * @returns {any[]}
 */
export const unionAdmTrees = (trees) => {
  const mergeUniqueByName = (items) => {
    const uniqueItems = new Map();

    items.forEach((item) => {
      const uniqueKey = `${item.id}-${item.type}`;
      if (!uniqueItems.has(uniqueKey)) {
        uniqueItems.set(uniqueKey, { ...item, checked: true });
      } else {
        const existingItem = uniqueItems.get(uniqueKey);
        // Merge aspects
        if (item.aspects) {
          if (existingItem.aspects) {
            existingItem.aspects = mergeUniqueByName([...existingItem.aspects, ...item.aspects]);
          } else {
            existingItem.aspects = mergeUniqueByName(item.aspects);
          }
        }
        // Merge summary_indicators
        if (item.summary_indicators) {
          if (existingItem.summary_indicators) {
            existingItem.summary_indicators = mergeUniqueByName([...existingItem.summary_indicators, ...item.summary_indicators]);
          } else {
            existingItem.summary_indicators = mergeUniqueByName(item.summary_indicators);
          }
        }
        // Merge indicators
        if (item.indicators) {
          if (existingItem.indicators) {
            existingItem.indicators = mergeUniqueByName([...existingItem.indicators, ...item.indicators]);
          } else {
            existingItem.indicators = mergeUniqueByName(item.indicators);
          }
        }
      }
    });
    return Array.from(uniqueItems.values());
  };

  return mergeUniqueByName(trees.flat());
};

/**
 * Merge observations from multiple surveys into one array.
 * Each observation has a unique ID
 * @param observations
 * @returns {*}
 */
export const mergeObservations = (observations) => {
  return observations.flat();
};

export const filterAdmTrees = (admTrees, selectedNodes) => {
  const isSelectedNode = (node) => {
    return selectedNodes.some((selectedNode) => node.id === selectedNode.id && node.type === selectedNode.type && node.name === selectedNode.name);
  };

  const updateNodeCheckedStatus = (nodes) => {
    return nodes.map((node) => {
      let newNode = { ...node, checked: isSelectedNode(node) };
      const childTypes = ['aspects', 'summary_indicators', 'indicators'];

      childTypes.forEach((childType) => {
        if (newNode[childType]) {
          newNode[childType] = updateNodeCheckedStatus(newNode[childType]);
        }
      });

      return newNode;
    });
  };

  return updateNodeCheckedStatus(admTrees);
};

const isTitleNode = (node) => {
  return node.survey_config.question_type === 'title';
};

const isAspectCategory = (node) => {
  return node.type === 'Care::AspectCategory';
};

/**
 * Check if all nodes in the ADM tree are point scale questions
 * Skip Aspect Category and Title nodes
 * @param admTrees
 * @returns {*}
 */
export const isAllNodesPointScale = (admTrees) => {
  const checkPointScale = (nodes) => {
    return nodes.every((node) => {
      if (node.survey_config && !isAspectCategory(node) && !isTitleNode(node)) {
        if (!isPointScaleQuestion(node)) {
          return false;
        }
      }
      const childTypes = ['aspects', 'summary_indicators', 'indicators'];
      return childTypes.every((childType) => {
        return !node[childType] || checkPointScale(node[childType]);
      });
    });
  };

  return checkPointScale(admTrees);
};
