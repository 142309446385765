import React from 'react';
import PropTypes from 'prop-types';
import { useCommonChartStyles } from './use_common_chart_styles';
import NoObservationMessage from './chart_no_data';
import { Paper, Typography, Box, Divider, Stack } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const LegendRectangle = ({ color }) => (
  <Box
    sx={{
      width: 24,
      height: 24,
      backgroundColor: color,
      margin: 0,
    }}
  />
);

const HqimInUseChart = ({ observations }) => {
  const { commonPaperStyle, commonBoxStyle, commonColor } = useCommonChartStyles();

  const yesCount = observations.filter((o) => o.hqim_in_use === true).length;
  const noCount = observations.filter((o) => o.hqim_in_use !== true).length;
  const totalCount = observations.length;
  const yesPercentage = (yesCount / totalCount) * 100;
  const noPercentage = (noCount / totalCount) * 100;

  const formatPercentage = (value) => Math.round(value);

  const chartData = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [yesCount, noCount],
        backgroundColor: [commonColor.green, commonColor.red],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Paper variant="outlined" sx={{ position: 'relative', ...commonPaperStyle }}>
      <Typography variant="h6" gutterBottom>
        HQIM In Use
      </Typography>
      {totalCount > 0 ? (
        <Box sx={{ ...commonBoxStyle, justifyContent: 'space-between' }}>
          <Box sx={{ width: '50%' }}>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
              <div>
                <Typography color="textSecondary">Yes</Typography>
                <Typography variant="body2">
                  <strong>{formatPercentage(yesPercentage)}%</strong>
                  <span>
                    &nbsp;({yesCount}/{totalCount})
                  </span>
                </Typography>
              </div>
              <LegendRectangle color={commonColor.green} />
            </Stack>
            <Divider sx={{ marginTop: '6px', marginBottom: '6px' }} />
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
              <div>
                <Typography color="textSecondary">No</Typography>
                <Typography variant="body2">
                  <strong>{formatPercentage(noPercentage)}%</strong>
                  <span>
                    &nbsp;({noCount}/{totalCount})
                  </span>
                </Typography>
              </div>
              <LegendRectangle color={commonColor.red} />
            </Stack>
          </Box>
          <Box sx={{ width: '50%' }}>
            <Doughnut data={chartData} options={options} />
          </Box>
        </Box>
      ) : (
        <NoObservationMessage />
      )}
    </Paper>
  );
};

HqimInUseChart.propTypes = {
  observations: PropTypes.array.isRequired,
};

export default HqimInUseChart;
