import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Link, Stack } from '@mui/material';
import { getFeatureFlagValue, updateFeatureFlagAfterClientInitialization } from '../../../helpers/get_feature_flag_value';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

const CareNav = ({ back_url, page_props, permission_props }) => {
  const backUrl = back_url;

  const [showObservableAnalysis, setShowObservableAnalysis] = useState(getFeatureFlagValue('show-teacher-analysis'));
  useEffect(updateFeatureFlagAfterClientInitialization('show-teacher-analysis', setShowObservableAnalysis), []);

  const { coaching_dashboard, goals, action_steps, care_admin, observations, reports, observables } = page_props || {};
  const { can_view_coaching_dashboard, can_view_goals, can_view_surveys, can_view_observations, can_view_reports, can_view_observables } =
    permission_props || {};

  const isActiveTab = (url) => window.location.href.startsWith(url);

  return (
    <div className="project-nav no-print">
      <Typography variant="h1">CARE Tool</Typography>
      <Stack direction="row">
        {showObservableAnalysis && can_view_coaching_dashboard && (
          <Link href={coaching_dashboard.url} className={isActiveTab(coaching_dashboard.url) ? 'active' : ''}>
            {coaching_dashboard.label}
          </Link>
        )}
        {showObservableAnalysis && can_view_goals && (
          <Link href={goals.url} className={isActiveTab(goals.url) ? 'active' : ''}>
            {goals.label}
          </Link>
        )}
        {can_view_surveys && (
          <Link href={care_admin.url} className={isActiveTab(care_admin.url) ? 'active' : ''}>
            {care_admin.label}
          </Link>
        )}
        {can_view_observations && (
          <Link href={observations.url} className={window.location.href === observations.url ? 'active' : ''}>
            {observations.label}
          </Link>
        )}
        {can_view_reports && (
          <Link href={reports.url} className={isActiveTab(reports.url) ? 'active' : ''}>
            {reports.label}
          </Link>
        )}
        {can_view_observables && (
          <Link href={observables.url} className={isActiveTab(observables.url) ? 'active' : ''}>
            {observables.label}
          </Link>
        )}
      </Stack>
      <div className="divider" />
      {backUrl && (
        <Link href={backUrl}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ color: '#67bbb9', marginRight: 5 }} />
          Back
        </Link>
      )}
    </div>
  );
};

CareNav.propTypes = {
  back_url: PropTypes.string,
};

export default CareNav;
