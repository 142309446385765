import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import CareNav from '../../../components/care/shared/nav';
import ObservableSummary from '../../../components/care/coaching_dashboards/observable_summary';
import { Typography, Stack } from '@mui/material';
import { OBSERVATION_TABLE_MODES } from '../../../components/care/coaching_dashboards/utils';
import ObservationTable from '../../../components/care/coaching_dashboards/observation_table';
import GoalsTable from '../../../components/care/goals/goals_table';
import { ConfirmDialogProvider } from '../../../components/modals/confirm_dialog';
import useGoalColumns from '../../../components/care/goals/use_goal_columns';
import { trackCzEvent } from '../../../helpers/track_cz_event';

const Container = ({ observations, observable, goals, summary_data, leads, recipients, goal_types, goal_indicators, ...props }) => {
  const isOnlyCareToolObservedUser = props.only_care_tool_observed_user;
  const action_steps_url = props.page_props.action_steps.url;
  const goals_url = props.page_props.goals.url;

  useEffect(() => {
    trackCzEvent('CAREView:ObserverDashboard', `User viewed observer dashboard for ${observable.email || observable.id}`, 1);
  }, []);

  const columnConfig = useGoalColumns({
    leads,
    showDescriptors: props.goal_descriptors_enabled,
    goalIndicators: goal_indicators,
    goalTypes: goal_types,
    configType: 'singleObservable',
    isEditable: !isOnlyCareToolObservedUser,
  });

  const teacherName = () => {
    if (observable) {
      return observable.name;
    }
    if (isOnlyCareToolObservedUser && props.observable_user) {
      return props.observable_user.full_name;
    }
    return '';
  };

  return (
    <ConfirmDialogProvider>
      {/*For the CARE Tool observed user role, the back button is hidden since the user is landed on this page (instead of the summary index)*/}
      <CareNav {...props} back_url={isOnlyCareToolObservedUser ? '' : props.back_url} />
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h4">Teacher: {teacherName()}</Typography>
      </Stack>

      <ObservableSummary observations={observations} summaryData={summary_data} />

      <GoalsTable
        title="All Goals"
        goals={goals}
        leads={leads}
        enableActionSteps={true}
        canAddActionStep={!isOnlyCareToolObservedUser}
        enableTableActions={!isOnlyCareToolObservedUser}
        columnConfig={columnConfig}
        actionStepsUrl={action_steps_url}
        observableGoalsBaseUrl={goals_url}
        tableName="observableDashGoals"
        goalsBaseUrl={goals_url}
      />

      <ObservationTable
        title="All Observations"
        observations={observations}
        baseUrl={props.base_url}
        observationsBaseUrl={props.observations_base_url}
        mode={OBSERVATION_TABLE_MODES.OBSERVABLE}
        observableGoalsBaseUrl={goals_url}
        actionStepsBaseUrl={action_steps_url}
        leads={leads}
        recipients={recipients}
        observableId={observable?.id}
        canShowCompare={false}
      />
    </ConfirmDialogProvider>
  );
};

Container.propTypes = {
  back_url: PropTypes.string.isRequired,
  base_url: PropTypes.string.isRequired,
  goal_descriptors_enabled: PropTypes.bool,
  summary_data: PropTypes.object.isRequired,
  leads: PropTypes.array,
  recipients: PropTypes.array,
  goals: PropTypes.array,
  observations_base_url: PropTypes.string,
  observable_user: PropTypes.object,
  permission_props: PropTypes.object.isRequired,
  page_props: PropTypes.object.isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props}></Container>
      </ThemeProvider>
    </Layout>
  );
};
