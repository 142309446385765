import React from 'react';
import PropTypes from 'prop-types';
import ActiveSurveyItem from './active_survey_item';
import { Paper, Stack, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

const ActiveSurveys = ({ surveys, onCreateObservation, baseUrlReports, loadingObservationStates, canViewActionMenu = true }) => {
  return (
    <Paper className="p-3 mt-4" elevation={2} data-cy="ActiveSurveys">
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <h5 className="blue-header">Open Templates</h5>
      </Stack>
      <Table size={'small'} sx={{ marginTop: 4 }}>
        {surveys.length > 0 ? (
          <>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: '40%', flexGrow: 1 }}>Name</TableCell>
                <TableCell sx={{ width: '10%' }}>Type</TableCell>
                <TableCell sx={{ width: '15%' }}>Created by</TableCell>
                <TableCell sx={{ width: '10%' }} align="center">
                  Observations
                </TableCell>
                <TableCell sx={{ width: '250px' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveys.map((survey) => (
                <ActiveSurveyItem
                  key={survey.id}
                  survey={survey}
                  onCreateObservation={onCreateObservation}
                  loadingObservationStates={loadingObservationStates}
                  baseUrlReports={baseUrlReports}
                  canViewActionMenu={canViewActionMenu}
                />
              ))}
            </TableBody>
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={5}
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  border: 0,
                  paddingTop: 0,
                  paddingBottom: 3,
                }}
              >
                <span className="text-muted">No items to display.</span>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </Paper>
  );
};

export default ActiveSurveys;

ActiveSurveys.propTypes = {
  surveys: PropTypes.arrayOf(PropTypes.object),
  onCreateObservation: PropTypes.func.isRequired,
  baseUrlReports: PropTypes.string.isRequired,
};
