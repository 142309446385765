/**
 * Smart truncation of a string
 * @param str - String to truncate
 * @param options - Options for truncation, defaults to maxLength: 25, ellipsisPosition: 'default'
 * @param options.maxLength - Maximum length of the string
 * @param options.ellipsisPosition - 'head', 'middle', 'tail', 'exact-end'
 * @returns {*|string}
 */
export const smartCrop = (str, options = {}) => {
  if (typeof str !== 'string') {
    console.warn('smartCrop: Input must be a string');
    return str;
  }

  const { maxLength = 25, ellipsisPosition = 'default' } = options;
  const ellipsis = '...';
  const ellipsisLength = ellipsis.length;
  const effectiveMaxLength = Math.max(maxLength, ellipsisLength + 3 + 1);

  if (str.length <= maxLength) {
    return str;
  }

  let truncatedStr = str;
  switch (ellipsisPosition) {
    case 'head': {
      // Ellipsis closer to the start
      const remainingLength = effectiveMaxLength - ellipsisLength - 3;
      const end = str.slice(-remainingLength);
      truncatedStr = `${str.slice(0, 3)}${ellipsis}${end}`;
      break;
    }
    case 'middle': {
      // Ellipsis in the exact middle
      const halfMaxLength = (effectiveMaxLength - 3) / 2;
      const startMiddle = str.slice(0, halfMaxLength);
      const endMiddle = str.slice(-halfMaxLength);
      truncatedStr = `${startMiddle}${ellipsis}${endMiddle}`;
      break;
    }
    case 'tail': {
      // Ellipsis closer to the end
      const startLength = effectiveMaxLength - ellipsisLength - 3;
      const startEnd = str.slice(0, startLength);
      truncatedStr = `${startEnd}${ellipsis}${str.slice(-3)}`;
      break;
    }
    case 'default':
    default: {
      // Default behavior: Ellipsis at the exact end
      truncatedStr = `${str.slice(0, effectiveMaxLength - ellipsisLength)}${ellipsis}`;
      break;
    }
  }
  return truncatedStr;
};
