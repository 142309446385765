import React, { useEffect, useState } from 'react';
import { Button, FormControl, TextField, Stack, Typography, Select, MenuItem, FormHelperText, InputLabel } from '@mui/material';
import { createDataModelNode, updateDataModelNode } from '../../../apis/care/data_models/api';
import { useConfirmDialog } from '../../modals/confirm_dialog';

export default function IndicatorForm({ node, parent, onClose, afterSave, contentAreas, ...props }) {
  const [number, setNumber] = useState(node?.number || '');
  const [name, setName] = useState(node?.name || '');
  const [description, setDescription] = useState(node?.description || '');
  const [contentAreaId, setContentAreaId] = useState(node?.content_area_id);
  const [errors, setErrors] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const isEditing = !!node;
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  useEffect(() => {
    if (isEditing) {
      if (number !== node.number || name !== node.name || description !== node.description || contentAreaId !== node.content_area_id) {
        setHasUnsavedChanges(true);
      }
    } else {
      if (number || name || description || contentAreaId) {
        setHasUnsavedChanges(true);
      }
    }
  }, [number, name, description, contentAreaId]);

  const handleSave = async (e) => {
    let response;
    if (isEditing) {
      response = await updateDataModelNode(
        { name, description, number, content_area_id: contentAreaId, id: node.id, type: 'Care::Indicator' },
        `indicators`
      );
    } else {
      response = await createDataModelNode(
        { name, description, number, content_area_id: contentAreaId, type: 'Care::Indicator', parent: { id: parent.id, type: parent.type } },
        'indicators'
      );
    }
    if (response.errors) {
      setErrors(response.errors);
    } else {
      setErrors({});
      afterSave(response);
    }
  };

  const hasErrors = (prop) => errors && errors[prop]?.length > 0;

  const confirmCancel = () => {
    if (hasUnsavedChanges) {
      showConfirmDialog({
        title: 'Unsaved Changes',
        description: 'You have unsaved changes. Are you sure you want to discard them?',
        actions: [
          {
            label: 'Discard Changes',
            variant: 'text',
            color: 'primary',
            autoFocus: false,
            onClick: async () => {
              onClose();
              hideConfirmDialog();
            },
          },
          {
            label: 'Keep Editing',
            variant: 'contained',
            color: 'primary',
            onClick: () => {
              hideConfirmDialog();
            },
          },
        ],
      });
    } else {
      onClose();
    }
  };

  return (
    <>
      <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack direction="column" spacing={2}>
          {parent && (
            <Typography variant="body">
              Summary Indicator {parent.number}: {parent.name}
            </Typography>
          )}
          <Typography variant="h6"> {node ? `Edit ${node.name}` : 'New Indicator'} </Typography>
          <TextField
            label="Number"
            variant="outlined"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            error={hasErrors('number')}
            helperText={errors['number']}
          />
          <TextField
            required
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={hasErrors('name')}
            helperText={errors['name']}
          />
          <TextField label="Description" variant="outlined" multiline value={description} onChange={(e) => setDescription(e.target.value)} />
          <FormControl fullWidth error={hasErrors('content_area')}>
            <InputLabel id="content-area-select-label">Content Area</InputLabel>
            <Select
              labelId="content-area-select-label"
              id="content-area-select"
              label="Content Area"
              value={contentAreaId}
              onChange={(e) => setContentAreaId(e.target.value)}
            >
              {contentAreas.map((area) => (
                <MenuItem key={area.id} value={area.id}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>
            {errors['content_area'] && <FormHelperText>{errors['content_area']}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={confirmCancel}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
