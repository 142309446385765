import { Stack, Typography } from '@mui/material';
import React from 'react';
import { truncate } from 'lodash';

// TODO – remove the workaround of assuming a number is a percentage if it's over 5
const TopThree = ({ title, data, marginTop, marginBottom, decimalPlaces = 1, countOnly = false }) => {
  const formatNumber = (number) => {
    if (countOnly) {
      return number;
    } else if (number > 5) {
      return `${parseInt(number)}%`;
    } else {
      return parseFloat(number).toFixed(decimalPlaces);
    }
  };
  return (
    <Stack direction="column" sx={{ mb: `${marginBottom ? 2 : 0}` }}>
      {title && (
        <Typography variant="h6" sx={{ mt: `${marginTop ? 2 : 0}` }}>
          {title}
        </Typography>
      )}
      {data.slice(0, 3).map((indicator) => (
        <Stack direction="row" spacing={1} key={indicator[0]}>
          <Typography variant="body1">{truncate(indicator[0], { length: 30 })}</Typography>
          <Typography variant="body1">({formatNumber(indicator[1])})</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default TopThree;
