import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../../constants/theme';
import { formatDate } from '../../../helpers/date_utils';
import { Paper, Stack, Table, TableHead, TableSortLabel, TableBody, TableRow, TableCell, Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CareNav from '../../../components/care/shared/nav';
import DelegatedTag from '../../../components/shared/delegations/survey/delegated_tag';
import DelegatedBadge from '../../../components/shared/delegations/survey/delegated_badge';
import { trackCzEvent } from '../../../helpers/track_cz_event';

const tableTitle = (surveyTypeCategory) => {
  const { name } = surveyTypeCategory;
  switch (name) {
    case 'CARE Overview':
      return 'Overviews';
    case 'CARE Detail':
      return 'Details';
    case 'Archive':
      return 'Archive';
    default:
      return name + 's';
  }
};

const sortSurveys = (surveys, property, order) => {
  if (!surveys) return [];
  const surveysCopy = [...surveys];
  let compareFunction;

  switch (property) {
    case 'name':
      compareFunction = (a, b) => a[property]?.localeCompare(b[property]);
      break;
    case 'created_at':
      compareFunction = (a, b) => new Date(a[property]) - new Date(b[property]);
      break;
    case 'observations':
      compareFunction = (a, b) => a[property]?.length - b[property]?.length;
      break;
    default:
      compareFunction = () => 0;
  }

  return surveysCopy.sort((a, b) => {
    let comparison = compareFunction(a, b);
    return order === 'asc' ? comparison : -comparison;
  });
};

const SortableTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      label: 'Name',
      sortable: true,
      sx: { minWidth: '50%' },
    },
    {
      id: 'created_at',
      numeric: false,
      label: 'Created on',
      sortable: true,
      sx: { width: '145px' },
    },
    {
      id: 'observations',
      numeric: true,
      label: 'Completed Observations',
      sortable: true,
      sx: { width: '30px' },
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      sortable: false,
      sx: { width: '100px' },
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            {...(headCell.sx && { sx: headCell.sx })}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const CategoryTable = ({ baseUrl, surveyTypeCategory, onSurveySelect, selectedSurveys }) => {
  const [orderBy, setOrderBy] = useState('created_at');
  const [order, setOrder] = useState('desc');
  const [sortedSurveys, setSortedSurveys] = useState(surveyTypeCategory.surveys || []);

  useEffect(() => {
    setSortedSurveys(sortSurveys(surveyTypeCategory.surveys, orderBy, order));
  }, [orderBy, order, surveyTypeCategory.surveys]);

  if (surveyTypeCategory.surveys.length === 0) {
    return null;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    setSortedSurveys(sortSurveys(surveyTypeCategory.surveys, property, newOrder));
  };

  const renderDelegationTag = (survey) => {
    const isDelegated = survey['delegated?'];
    const isCreatedByDelegation = survey['created_by_delegation?'];
    const delegatedFromName = survey['delegated_from_org_name'];

    if (isDelegated) {
      return <DelegatedTag />;
    }
    if (isCreatedByDelegation) {
      return <DelegatedBadge tooltipLabel={`Delegated from ${delegatedFromName}`} />;
    }
    return null;
  };

  return (
    <Paper className="p-3 mt-4" elevation={2} data-cy={surveyTypeCategory.name}>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <h5 className="blue-header">{tableTitle(surveyTypeCategory)}</h5>
      </Stack>
      <Table size={'small'} sx={{ marginTop: 4 }}>
        <SortableTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {sortedSurveys.map((survey) => (
            <TableRow key={survey.id}>
              <TableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                  <div>{survey.name}</div>
                  {renderDelegationTag(survey)}
                </Stack>
              </TableCell>
              <TableCell>{formatDate(survey.created_at)}</TableCell>
              <TableCell align="right">{survey['hierarchical_completed_observations_count']}</TableCell>
              <TableCell sx={{ width: '100px' }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      trackCzEvent('CAREView:DataAnalysis', 'User viewed data analysis', 1);
                      window.location.href = `${baseUrl}/${survey.id}${survey['delegated?'] ? '?with_delegations=true' : ''}`;
                    }}
                  >
                    View
                  </Button>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedSurveys.includes(survey.id)}
                        onChange={(e) => onSurveySelect(survey.id, e.target.checked)}
                        name="compare"
                      />
                    }
                  />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

const Container = (props) => {
  const [selectedSurveys, setSelectedSurveys] = useState([]);

  const handleSurveySelect = (surveyId, isSelected) => {
    setSelectedSurveys((prevSelectedSurveys) => {
      if (isSelected) {
        return [...prevSelectedSurveys, surveyId];
      } else {
        return prevSelectedSurveys.filter((id) => id !== surveyId);
      }
    });
  };

  const isAnyDelegated = () =>
    props.survey_type_categories
      .map((stc) => stc.surveys)
      .flat()
      .some((survey) => {
        return selectedSurveys.includes(survey.id) && survey['delegated?'];
      });

  const handleCompare = () => {
    trackCzEvent('CARECompare:DataAnalysis', 'Users compared templates in data analysis ', selectedSurveys.length);
    window.location.href = `${props.base_url}/compare?survey_ids=${selectedSurveys.join(',')}${isAnyDelegated() ? '&with_delegations=true' : ''}`;
  };

  const handleView = () => {
    trackCzEvent('CAREView:DataAnalysis', 'User viewed data analysis', 1);
    window.location.href = `${props.base_url}/observations?survey_ids=${selectedSurveys.join(',')}${isAnyDelegated() ? '&with_delegations=true' : ''}`;
  };

  return (
    <>
      <CareNav {...props} back_url={''} />
      <Stack justifyContent="flex-end" direction="row" spacing={1}>
        <Button variant="contained" color="primary" disabled={selectedSurveys.length < 2} onClick={handleView}>
          View
        </Button>
        <Button variant="contained" color="primary" disabled={selectedSurveys.length < 2} onClick={handleCompare}>
          Compare
        </Button>
      </Stack>
      {props.survey_type_categories.map((stc) => (
        <div key={stc.id} className="mt-4">
          <CategoryTable baseUrl={props.base_url} surveyTypeCategory={stc} onSurveySelect={handleSurveySelect} selectedSurveys={selectedSurveys} />
        </div>
      ))}
      {}
      <CategoryTable
        baseUrl={props.base_url}
        surveyTypeCategory={{
          name: 'Archive',
          surveys: props.archived_surveys,
        }}
        onSurveySelect={handleSurveySelect}
        selectedSurveys={selectedSurveys}
      />
    </>
  );
};

Container.propTypes = {
  base_url: PropTypes.string.isRequired,
  survey_type_categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  archived_surveys: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <Container {...props}></Container>
      </ThemeProvider>
    </Layout>
  );
};
