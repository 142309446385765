import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Resources from '../../shared/resources';
import UploadAction from '../../actions/upload';
import { Grid } from '@mui/material';

const Attachments = ({ ...props }) => {
  const [attachments, setAttachments] = useState(props?.survey?.attachments || []);

  const handleUpload = (attachment) => {
    setAttachments((prevAttachments) => [...prevAttachments, attachment]);
  };

  const handleRemove = (attachments) => {
    setAttachments(attachments);
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <span className="me-2">Attachments</span>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'right' }}>
        <Resources resources={attachments} handleUpdate={handleRemove} />
        <UploadAction url={`/care/surveys/${props.survey.id}/attach_file`} handleUpload={handleUpload} className="ms-2" />
      </Grid>
    </Grid>
  );
};

Attachments.propTypes = {
  survey: PropTypes.object,
  attachments: PropTypes.array,
  handleUpload: PropTypes.func,
};

export default Attachments;
