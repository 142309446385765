import React, { useEffect, useState, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdmTooltip from '../../shared/adm_tooltip';
import AdmDescription from '../../shared/adm_description';
import Checkbox from '@mui/material/Checkbox';
import AccordionDetails from '@mui/material/AccordionDetails';
import Indicator from './indicator';
import { RubricToggleLink } from '../../shared/rubric_toggle/rubric_toggle_link';
import { RubricContent } from '../../shared/rubric_toggle/rubric_content';

const SummaryIndicator = ({ summaryIndicator, onChange, isIncluded, isAllExpanded, showAdmNumbers }) => {
  const [expanded, setExpanded] = useState(isAllExpanded);
  const [showRubric, setShowRubric] = useState(false);
  const detailsRef = useRef(null);

  useEffect(() => {
    setExpanded(isAllExpanded);
  }, [isAllExpanded]);

  const toggleRubric = (e) => {
    e.stopPropagation();
    setShowRubric(!showRubric);
  };

  const hasIndicators = summaryIndicator.indicators?.length > 0;
  const indicatorIds = summaryIndicator.indicators.map((indicator) => indicator.id);
  const parentIsChecked = isIncluded('summary_indicators', summaryIndicator.id);
  const allChildrenChecked = indicatorIds.every((id) => isIncluded('indicators', id));
  const includedCount = indicatorIds.filter((id) => isIncluded('indicators', id)).length;
  const isAllChecked = () => parentIsChecked && allChildrenChecked;

  const handleCheckAll = (e) => {
    e.stopPropagation();
    if (parentIsChecked && allChildrenChecked) {
      onChange('summary_indicators', summaryIndicator.id);
      onChange('indicators', indicatorIds);
      return;
    }

    const uncheckedIndicatorIds = indicatorIds.filter((id) => !isIncluded('indicators', id));
    if (uncheckedIndicatorIds.length > 0) {
      onChange('indicators', uncheckedIndicatorIds);
    }
    if (!parentIsChecked) {
      onChange('summary_indicators', summaryIndicator.id);
    }
  };

  const getCheckboxState = () => {
    if (indicatorIds.length === 0) {
      return {
        checked: parentIsChecked,
        indeterminate: false,
      };
    }

    if (includedCount === 0) {
      return { checked: parentIsChecked, indeterminate: false };
    }
    if (includedCount === indicatorIds.length) {
      return { checked: parentIsChecked, indeterminate: !parentIsChecked };
    }
    if (includedCount > 0 && includedCount < indicatorIds.length) {
      return { checked: parentIsChecked, indeterminate: !parentIsChecked };
    }

    return { checked: false, indeterminate: false };
  };

  const { checked, indeterminate } = getCheckboxState();

  return (
    <div className="summary-indicator mt-1">
      <Accordion
        elevation={0}
        expanded={expanded}
        sx={{
          '& .MuiAccordionSummary-root': {
            alignItems: 'baseline',
          },
        }}
      >
        <AccordionSummary
          onClick={(e) => setExpanded(!expanded)}
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: '#F5F8FA',
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              width: '100%',
              gap: (theme) => theme.spacing(2),
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              position: 'relative',
              top: '7px',
            },
          }}
        >
          {/* Left */}
          <div className="py-4">
            <div className="d-flex">
              <h6 className="mb-0 headline-color">
                <strong>
                  {showAdmNumbers && summaryIndicator.number && <span>{summaryIndicator.number}&nbsp;</span>}
                  {summaryIndicator.name}
                </strong>
              </h6>
              <AdmTooltip config={summaryIndicator.survey_config} />
            </div>
            {expanded && summaryIndicator.rubric && summaryIndicator.rubric !== '<p></p>' ? (
              <RubricToggleLink showRubric={showRubric} onToggle={toggleRubric} />
            ) : (
              <div className={`pt-1 summary-indicator-description ${expanded ? 'show' : ''}`}>
                <AdmDescription description={summaryIndicator.description} />
              </div>
            )}
          </div>

          {/* Right */}
          <div className="d-flex align-items-center ms-5">
            {hasIndicators ? (
              expanded ? (
                <a className={`link-default summary-indicator-check-all ${expanded ? 'show' : ''}`} onClick={handleCheckAll}>
                  {isAllChecked() ? 'Uncheck All' : 'Check All'}
                </a>
              ) : (
                <span style={{ color: '#a7a7a7' }}>{`${includedCount + (parentIsChecked ? 1 : 0)}/${indicatorIds.length + 1}`}</span>
              )
            ) : (
              <div>&nbsp;</div>
            )}

            {summaryIndicator.survey_config.question_type !== 'title' && (
              <Checkbox
                onChange={(e) => {
                  e.stopPropagation();
                  if (e.target.checked) {
                    setExpanded(true);
                  }
                  onChange('summary_indicators', summaryIndicator.id);
                }}
                onClick={(e) => e.stopPropagation()}
                disabled={summaryIndicator.survey_config.required}
                checked={checked}
                indeterminate={indeterminate}
                sx={{
                  pointerEvents: 'auto',
                }}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails ref={detailsRef}>
          {showRubric && <RubricContent rubricHtml={summaryIndicator.rubric} show={showRubric} />}
          {summaryIndicator.indicators.map((indicator) => (
            <Indicator key={indicator.id} indicator={indicator} isIncluded={isIncluded} showAdmNumbers={showAdmNumbers} onChange={onChange} />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SummaryIndicator;
