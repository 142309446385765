import React, { useMemo } from 'react';
import NoObservationMessage from './chart_no_data';
import { useCommonChartStyles } from './use_common_chart_styles';
import { Paper, Typography, Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { smartCrop } from '../../../../helpers/string_utils';

const SchoolNameChart = ({ observations }) => {
  const { commonPaperStyle, commonBoxStyle, commonColor } = useCommonChartStyles();

  const totalCount = observations.length;

  const { labels, dataPoints, originalNames } = useMemo(() => {
    const counts = {};
    const originalNames = {};
    observations.forEach(({ school_name }) => {
      counts[school_name.name] = (counts[school_name.name] || 0) + 1;
    });

    const labels = [];
    Object.keys(counts).forEach((name) => {
      const croppedName = smartCrop(name, { maxLength: 24, ellipsisPosition: 'middle' });
      labels.push(croppedName);
      originalNames[croppedName] = name;
    });

    const dataPoints = Object.values(counts);
    return { labels, dataPoints, originalNames };
  }, [observations]);

  const maxYValue = Math.max(...dataPoints) + 1;

  const data = {
    labels,
    datasets: [
      {
        label: 'Number of Observations',
        data: dataPoints,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const originalName = originalNames[context.label] || context.label;
            return `${originalName}: ${context.parsed.y}`;
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: maxYValue,
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <Paper variant="outlined" sx={{ ...commonPaperStyle }}>
      <Typography variant="h6" gutterBottom>
        Observations per School
      </Typography>
      {totalCount > 0 ? (
        <Box sx={{ ...commonBoxStyle, justifyContent: 'space-between' }}>
          <Bar data={data} options={options} />
        </Box>
      ) : (
        <NoObservationMessage />
      )}
    </Paper>
  );
};

export default SchoolNameChart;
