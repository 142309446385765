import React from 'react';
import PropTypes from 'prop-types';
import { useCommonChartStyles } from './use_common_chart_styles';
import NoObservationMessage from './chart_no_data';
import { Paper, Typography, Box } from '@mui/material';

const minPercentageThreshold = 10;
const minCircleSize = 70; // min circle size for the 10% threshold
const maxCircleSize = 150; // max circle size
const smallPaddingPct = 0.1;
const largePaddingPct = 0.2;

const Circle = ({ backgroundColor, fontColor, size, label, count, percentage }) => {
  const fontSizePercentage = `min(20px, calc(${size} / 4))`;
  const fontSizePercentageVal = Math.min(20, parseInt(size) / 4);

  const fontSizeCountValue = fontSizePercentageVal > 12 ? fontSizePercentageVal - 3 : fontSizePercentageVal;
  const fontSizeCount = `${fontSizeCountValue}px`;

  const paddingPercentage = parseInt(size) <= minCircleSize ? smallPaddingPct : largePaddingPct;
  const padding = parseInt(size) * paddingPercentage;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '150px',
      }}
    >
      <Box
        className="att-chart-circle"
        sx={{
          width: `calc(${size} - ${padding}px)`,
          height: `calc(${size} - ${padding}px)`,
          borderRadius: '50%',
          backgroundColor: backgroundColor,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: `${padding}px`,
        }}
      >
        <Typography variant="h5" color={fontColor} sx={{ fontSize: fontSizePercentage }}>
          <strong>{percentage}%</strong>
        </Typography>
        <Typography variant="body1" color={fontColor} sx={{ fontSize: fontSizeCount }}>
          ({count})
        </Typography>
      </Box>
      <Typography color="textSecondary" sx={{ fontSize: 13, color: 'black', marginTop: 1 }}>
        {label}
      </Typography>
    </Box>
  );
};

const StudentsEngagedChart = ({ observations }) => {
  const { commonPaperStyle, commonBoxStyle, commonColor } = useCommonChartStyles();

  const totalObservations = observations.length;
  const countMoreThan80 = observations.reduce((acc, obs) => {
    const percent = (obs.number_of_engaged_students / obs.number_of_students) * 100;
    return percent > 79 ? acc + 1 : acc;
  }, 0);
  const countLessThan80 = totalObservations - countMoreThan80;

  const calculatePercentage = (count) => {
    return totalObservations > 0 ? Math.round((count / totalObservations) * 100) : '0';
  };

  const calculateSize = (percentage) => {
    // Ensure the percentage is not below the minimum threshold
    percentage = Math.max(percentage, minPercentageThreshold);

    // Calculate the size as a percentage of the range between minCircleSize and maxCircleSize
    const size = minCircleSize + ((percentage - minPercentageThreshold) / (100 - minPercentageThreshold)) * (maxCircleSize - minCircleSize);

    return Math.round(size); // Round to the nearest whole number for pixel value
  };

  const percentageMoreThan80 = calculatePercentage(countMoreThan80);
  const percentageLessThan80 = calculatePercentage(countLessThan80);
  const sizeMoreThan80 = calculateSize(parseFloat(percentageMoreThan80));
  const sizeLessThan80 = calculateSize(parseFloat(percentageLessThan80));

  return (
    <Paper
      variant="outlined"
      sx={{
        ...commonPaperStyle,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Classrooms with at least 80% Student Engagement
      </Typography>
      {totalObservations > 0 ? (
        <Box sx={{ ...commonBoxStyle, justifyContent: 'center' }}>
          {countLessThan80 > 0 && (
            <Circle
              backgroundColor={commonColor.red}
              fontColor={commonColor.black}
              size={`${sizeLessThan80}px`}
              count={countLessThan80}
              percentage={percentageLessThan80}
              label="Less than 80%"
            />
          )}
          {countMoreThan80 > 0 && (
            <Circle
              backgroundColor={commonColor.green}
              fontColor={commonColor.white}
              size={`${sizeMoreThan80}px`}
              count={countMoreThan80}
              percentage={percentageMoreThan80}
              label="More than 80%"
            />
          )}
        </Box>
      ) : (
        <NoObservationMessage />
      )}
    </Paper>
  );
};

StudentsEngagedChart.propTypes = {
  observations: PropTypes.array.isRequired,
};

export default StudentsEngagedChart;
