import React from 'react';
import PropTypes from 'prop-types';
import { useCommonChartStyles } from './use_common_chart_styles';
import NoObservationMessage from './chart_no_data';
import { Paper, Typography, Box } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

const AvgStudentEngagementChart = ({ observations }) => {
  const { commonPaperStyle, commonBoxStyle, commonColor } = useCommonChartStyles();

  const engagedStudents = observations.reduce((acc, observation) => {
    return acc + observation.number_of_engaged_students;
  }, 0);
  const totalStudents = observations.reduce((acc, observation) => {
    return acc + observation.number_of_students;
  }, 0);

  const percentage = Math.min(100, Math.round((engagedStudents / totalStudents) * 100));

  const calculateColor = (percentage) => {
    if (percentage >= 80) return commonColor.green;
    if (percentage >= 70) return commonColor.yellow;
    return commonColor.red;
  };

  const chartData = {
    labels: ['Engagement'],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [calculateColor(percentage), commonColor.grey],
        borderWidth: 0,
        borderRadius: 0,
      },
    ],
  };

  return (
    <Paper variant="outlined" sx={{ ...commonPaperStyle }}>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{
          lineHeight: '1.2',
        }}
      >
        Average Student Engagement
      </Typography>
      {totalStudents > 0 ? (
        <Box sx={{ ...commonBoxStyle, justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box sx={{ width: '50%' }}>
            <Typography variant="h3">
              <strong>{percentage}%</strong>
            </Typography>
          </Box>
          <Box sx={{ width: '50%', maxHeight: '130px', marginTop: '1rem' }}>
            <Doughnut data={chartData} options={options} />
          </Box>
        </Box>
      ) : (
        <NoObservationMessage />
      )}
    </Paper>
  );
};

AvgStudentEngagementChart.propTypes = {
  observations: PropTypes.array.isRequired,
};

export default AvgStudentEngagementChart;
