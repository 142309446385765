import {
  observationsWithResponseForAdmNode,
  observationsWithResponseForAdmNodeByValue,
  sumResponsesByAdmNode,
} from '../observation_adm_node_filter_utils';
import { gradeLevelToNumber, isGradeInSelectedRanges, hqimInUseToNumber, gradeRanges } from '../filters/utils';

export const isDetail = (name) => name === 'CARE Detail';

export const isOverview = (name) => name === 'CARE Overview';

export const isAspect = (nodeType) => nodeType === 'Care::Aspect';

export const isSummaryIndicator = (nodeType) => nodeType === 'Care::SummaryIndicator';

export const isTitleNode = (nodeConfig) => {
  if (!nodeConfig || !nodeConfig.question_type) return false;
  return nodeConfig.question_type === 'title';
};

export const formatPercentage = (value) => {
  if (isNaN(value)) {
    return 0;
  } else {
    return Math.round(value * 100);
  }
};

export const computeHeatMapValuesForAdmNode = (
  admNode,
  observations,
  showHqimColumns,
  isPointScale,
  showSummaryOnly = false,
  shouldSumResponses = false
) => {
  let col1_observations, col1NodeResponses;
  let col2_observations, col2NodeResponses;
  let allNodeResponsesByValue, col1NodeResponsesByValue, col2NodeResponsesByValue;

  if (showSummaryOnly) {
    const allNodeResponses = observationsWithResponseForAdmNode(observations, admNode);
    if (isPointScale || shouldSumResponses) {
      allNodeResponsesByValue = sumResponsesByAdmNode(observations, admNode);
    } else {
      allNodeResponsesByValue = observationsWithResponseForAdmNodeByValue(observations, admNode, 'yes');
    }
    return {
      allNodeResponses,
      allNodeResponsesByValue,
      col1NodeResponses,
      col1NodeResponsesByValue,
      col2NodeResponses,
      col2NodeResponsesByValue,
    };
  }

  if (showHqimColumns) {
    col1_observations = observations.filter((o) => o.hqim_in_use === true);
    col2_observations = observations.filter((o) => o.hqim_in_use === false);
  } else {
    col1_observations = observations.filter((o) => o.content_area?.name === 'ELA');
    col2_observations = observations.filter((o) => o.content_area?.name === 'Math');
  }

  const allNodeResponses = observationsWithResponseForAdmNode(observations, admNode);
  col1NodeResponses = observationsWithResponseForAdmNode(col1_observations, admNode);
  col2NodeResponses = observationsWithResponseForAdmNode(col2_observations, admNode);

  if (isPointScale || shouldSumResponses) {
    allNodeResponsesByValue = sumResponsesByAdmNode(observations, admNode);
    col1NodeResponsesByValue = sumResponsesByAdmNode(col1_observations, admNode);
    col2NodeResponsesByValue = sumResponsesByAdmNode(col2_observations, admNode);
  } else {
    allNodeResponsesByValue = observationsWithResponseForAdmNodeByValue(observations, admNode, 'yes');
    col1NodeResponsesByValue = observationsWithResponseForAdmNodeByValue(col1_observations, admNode, 'yes');
    col2NodeResponsesByValue = observationsWithResponseForAdmNodeByValue(col2_observations, admNode, 'yes');
  }

  return {
    allNodeResponses,
    allNodeResponsesByValue,
    col1NodeResponses,
    col1NodeResponsesByValue,
    col2NodeResponses,
    col2NodeResponsesByValue,
  };
};

export const groupObservationsBy = (groupBy, observations) => {
  if (!groupBy || groupBy === 'default') return { default: observations };

  const propKeyMapping = {
    school_name: 'name',
    content_area: 'name',
    observable: 'name',
    observer: 'full_name',
    grade_level: 'name',
    grade_range: null,
    instruction_language: 'name',
    diverse_classroom: null,
    hqim_in_use: null,
    default: null,
  };

  const sortSettingsMapping = {
    school_name: (a, b) => a.localeCompare(b),
    content_area: (a, b) => a.localeCompare(b),
    observable: (a, b) => a.localeCompare(b),
    observer: (a, b) => a.localeCompare(b),
    grade_level: (a, b) => gradeLevelToNumber(a) - gradeLevelToNumber(b),
    grade_range: (a, b) => gradeRanges.indexOf(a) - gradeRanges.indexOf(b),
    instruction_language: (a, b) => a.localeCompare(b),
    diverse_classroom: (a, b) => a.localeCompare(b),
    hqim_in_use: (a, b) => hqimInUseToNumber(a.toLowerCase()) - hqimInUseToNumber(b.toLowerCase()),
  };

  const propKey = propKeyMapping[groupBy];

  let grouped = observations.reduce((acc, observation) => {
    let groupName;
    if (groupBy === 'grade_range') {
      const grade = observation.grade_level;
      groupName = gradeRanges.find((range) => isGradeInSelectedRanges(grade, [range]));
    } else if (groupBy === 'hqim_in_use') {
      groupName = observation[groupBy] ? 'HQIM In Use - Yes' : 'HQIM In Use - No';
    } else {
      groupName = propKey === null ? observation[groupBy] : observation[groupBy]?.[propKey];
    }

    if (groupName == null) {
      return acc;
    }

    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(observation);
    return acc;
  }, {});

  const sortFunction = sortSettingsMapping[groupBy];
  if (sortFunction) {
    const sortedGroupKeys = Object.keys(grouped).sort(sortFunction);
    return sortedGroupKeys.reduce((acc, key) => {
      acc[key] = grouped[key];
      return acc;
    }, {});
  }

  return grouped;
};

export const areAllNodesSelected = (nodes) => {
  for (const node of nodes) {
    if (!node.checked) return false;
    if (node.aspects && !areAllNodesSelected(node.aspects)) return false;
    if (node.summary_indicators && !areAllNodesSelected(node.summary_indicators)) return false;
    if (node.indicators && !areAllNodesSelected(node.indicators)) return false;
  }
  return true;
};

export const hasAtLeastOneCheckedNode = (node) => {
  if (node.checked) return true;

  if (node.aspects) {
    for (const child of node.aspects) {
      if (hasAtLeastOneCheckedNode(child)) return true;
    }
  }
  if (node.summary_indicators) {
    for (const child of node.summary_indicators) {
      if (hasAtLeastOneCheckedNode(child)) return true;
    }
  }
  if (node.indicators) {
    for (const child of node.indicators) {
      if (hasAtLeastOneCheckedNode(child)) return true;
    }
  }

  return false;
};

export const initializeCheckedState = (nodes) => {
  return nodes.map((node) => {
    node.checked = true;

    if (node.aspects) {
      initializeCheckedState(node.aspects);
    }
    if (node.summary_indicators) {
      initializeCheckedState(node.summary_indicators);
    }
    if (node.indicators) {
      initializeCheckedState(node.indicators);
    }

    return node;
  });
};

/**
 * Returns the bucket for a given value.
 * Rounds half values down (3.5 => bucket 3, 3.6 => bucket 4)
 * @param value
 * @returns {number}
 */
export const getPointBucketFromValueOLD = (value) => {
  if (value % 1 === 0.5) {
    return Math.floor(value);
  } else {
    return Math.round(value);
  }
};

/**
 * Returns the bucket for a given value.
 * Uses custom rounding rules:
 *  <   1.3  rounds down to  1.0
 *  <   1.8  rounds down to  1.5
 *  <   2.3  rounds down to  2.0
 *  <   2.8  rounds down to  2.5
 *  <   3.3  rounds down to  3.0
 *  <   3.8  rounds down to  3.5
 *  <   4.3  rounds down to  4.0
 *  <   4.8  rounds down to  4.5
 *  >=  4.8  rounds up to    5.0
 * @param value
 * @returns {number}
 */
export const getPointBucketFromValue = (value, zeroBased = false) => {
  if (zeroBased) {
    return value;
  } else {
    if (value < 1.3) return 1.0;
    if (value < 1.8) return 1.5;
    if (value < 2.3) return 2.0;
    if (value < 2.8) return 2.5;
    if (value < 3.3) return 3.0;
    if (value < 3.8) return 3.5;
    if (value < 4.3) return 4.0;
    if (value < 4.8) return 4.5;
    if (value < 5.3) return 5.0;
    if (value < 5.8) return 5.5;
    if (value < 6.3) return 6.0;
    if (value < 6.8) return 6.5;
  }
  return 7.0;
};
