import React, { useState } from 'react';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';

const AdmConfigDialog = ({ admTree, isDialogOpen, setIsDialogOpen, handleAdmTreeChange, isDetailSurvey }) => {
  const [localAdmTree, setLocalAdmTree] = useState(() => admTree);

  const handleCheckChange = (nodeToChange, isChecked) => {
    const updateCheckedState = (nodes, key) => {
      return nodes.map((node) => {
        if (node.id === nodeToChange.id && node.type === nodeToChange.type) {
          return { ...node, checked: isChecked };
        }

        if (node[key]) {
          return {
            ...node,
            [key]: updateCheckedState(node[key], key === 'aspects' ? 'summary_indicators' : 'indicators'),
          };
        }
        return node;
      });
    };

    setLocalAdmTree((prevAdmTree) => updateCheckedState(prevAdmTree, 'aspects'));
  };

  const handleSave = () => {
    handleAdmTreeChange(localAdmTree);
    setIsDialogOpen(false);
  };

  const renderCheckbox = (node) => (
    <FormControlLabel
      control={<Checkbox checked={node.checked} onChange={(e) => handleCheckChange(node, e.target.checked)} />}
      label={
        <span style={{ color: '#333' }}>
          {/*{node.number && <strong>{node.number}</strong>} {node.name}*/}
          {node.name}
        </span>
      }
    />
  );

  const renderIndicator = (indicator, key, depth) => (
    <Grid item xs={12} key={key} sx={{ marginLeft: depth * 2 }}>
      {renderCheckbox(indicator)}
    </Grid>
  );

  const renderSummaryIndicator = (summaryIndicator, key, depth) => (
    <Grid item xs={12} key={key} sx={{ marginLeft: depth * 2 }}>
      {renderCheckbox(summaryIndicator)}
      {summaryIndicator.indicators.map((indicator, i_index) => renderIndicator(indicator, i_index, depth + 1))}
    </Grid>
  );

  const renderAspect = (aspect, key, depth) => (
    <Grid item xs={12} key={key} sx={{ marginLeft: depth * 2 }}>
      {renderCheckbox(aspect)}
      {aspect.summary_indicators.map((summaryIndicator, si_index) => renderSummaryIndicator(summaryIndicator, si_index, depth + 1))}
    </Grid>
  );

  const renderAspectCategory = (aspectCategory, key, depth) => {
    return (
      <Grid item xs={12} key={key} sx={{ marginLeft: depth * 2 }}>
        {renderCheckbox(aspectCategory)}
        {aspectCategory.aspects.map((aspect, a_index) => renderAspect(aspect, a_index, depth + 1))}
      </Grid>
    );
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const updateCheckedState = (nodes, key, isChecked) => {
    return nodes.map((node) => {
      return {
        ...node,
        checked: typeof isChecked === 'function' ? isChecked(node) : isChecked,
        [key]: node[key] ? updateCheckedState(node[key], key === 'aspects' ? 'summary_indicators' : 'indicators', isChecked) : [],
      };
    });
  };

  const selectAll = () => {
    setLocalAdmTree((prevAdmTree) => updateCheckedState(prevAdmTree, 'aspects', true));
  };

  const selectNone = () => {
    setLocalAdmTree((prevAdmTree) => updateCheckedState(prevAdmTree, 'aspects', false));
  };

  const selectAspects = () => {
    setLocalAdmTree((prevAdmTree) => updateCheckedState(prevAdmTree, 'aspects', (node) => node.type === 'Care::Aspect'));
  };

  const selectSummaryIndicators = () => {
    setLocalAdmTree((prevAdmTree) => updateCheckedState(prevAdmTree, 'aspects', (node) => node.type === 'Care::SummaryIndicator'));
  };

  const selectIndicators = () => {
    setLocalAdmTree((prevAdmTree) => updateCheckedState(prevAdmTree, 'aspects', (node) => node.type === 'Care::Indicator'));
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        disableEscapeKeyDown={true}
        maxWidth={'sm'}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Customize ADM Table
          <Stack direction="row" spacing={1} alignItems="center" marginTop={1}>
            <Typography variant="body1">Select:</Typography>
            <Link href="#" onClick={selectAll} variant="body1" className="link-default">
              All
            </Link>

            {isDetailSurvey && (
              <>
                <Typography variant="body2">/</Typography>
                <Link href="#" onClick={selectAspects} variant="body1" className="link-default">
                  Aspects
                </Link>
                <Typography variant="body2">/</Typography>
                <Link href="#" onClick={selectSummaryIndicators} variant="body1" className="link-default">
                  Summary Indicators
                </Link>
                <Typography variant="body2">/</Typography>
                <Link href="#" onClick={selectIndicators} variant="body1" className="link-default">
                  Indicators
                </Link>
              </>
            )}

            <Typography variant="body2">/</Typography>
            <Link href="#" onClick={selectNone} variant="body1" className="link-default">
              None
            </Link>
          </Stack>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div id="scroll-dialog-description" tabIndex={-1} ref={descriptionElementRef}>
            <Grid container spacing={2}>
              {localAdmTree.map((aspectCategory, ac_index) => renderAspectCategory(aspectCategory, ac_index, 0))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => handleSave(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdmConfigDialog;
