export const OBSERVATION_TABLE_MODES = {
  COACH: 'coach',
  OBSERVABLE: 'observable',
};

export const ascending = (dataHash) => {
  return Object.entries(dataHash).sort((e1, e2) => {
    if (parseFloat(e1[1]) > parseFloat(e2[1])) {
      return -1;
    } else if (parseFloat(e1[1]) < parseFloat(e2[1])) {
      return 1;
    } else {
      return parseFloat(e1[0]) < parseFloat(e2[0]);
    }
  });
};

export const descending = (dataHash) => {
  return Object.entries(dataHash).sort((e2, e1) => {
    if (parseFloat(e1[1]) > parseFloat(e2[1])) {
      return -1;
    } else if (parseFloat(e1[1]) < parseFloat(e2[1])) {
      return 1;
    } else {
      return parseFloat(e1[0]) < parseFloat(e2[0]);
    }
  });
};
