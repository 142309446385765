import { getHeaders } from '../../headers';

export const createObservable = async (observable, baseUrl) => {
  const response = await fetch(`${baseUrl}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ observable }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const updateObservable = async (observable, baseUrl) => {
  const response = await fetch(`${baseUrl}/${observable.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify({ observable }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const deleteObservable = async (observableId, baseUrl) => {
  const response = await fetch(`${baseUrl}/${observableId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};
