import React from 'react';
import { faHourglassClock } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Box, Typography } from '@mui/material';
import { SummaryBox, SummaryEmptyStateIcon } from './summary_utils';
import { ascending, descending } from './utils';
import TopThree from './top_three';

const ObserverSummary = ({ observations, summaryData }) => {
  const mostObserved = ascending(summaryData.most_observed_nodes);
  const highestPerforming = ascending(summaryData.nodes_with_performance_totals);
  const lowestPerforming = descending(summaryData.nodes_with_performance_totals);

  return (
    <Grid container spacing={2} sx={{ marginTop: 3 }}>
      <Grid item xs={3}>
        <SummaryBox variant="outlined">
          <Box>
            <Typography variant="h3">{observations.length}</Typography>
            <Typography variant="body1">
              <strong>Total Observations</strong>
            </Typography>
            <Typography variant="body2">
              Last Observation: {new Date(summaryData?.last_date_observed)?.toLocaleDateString({}, { timeZone: 'UTC' })}
            </Typography>
          </Box>
        </SummaryBox>
      </Grid>
      <Grid item xs={3}>
        <SummaryBox variant="outlined">
          <Typography variant="body1">
            <strong>Most Observed Indicators</strong>
          </Typography>
          <Box>
            {mostObserved?.length > 0 ? (
              <TopThree data={mostObserved} decimalPlaces={0} marginBottom={0} countOnly />
            ) : (
              <SummaryEmptyStateIcon icon={faHourglassClock} />
            )}
          </Box>
        </SummaryBox>
      </Grid>
      <Grid item xs={3}>
        <SummaryBox variant="outlined">
          <Typography variant="body1">
            <strong>Highest Performing Indicators</strong>
          </Typography>
          <Box>
            {highestPerforming?.length > 0 ? (
              <TopThree data={highestPerforming} decimalPlaces={1} marginBottom={0} />
            ) : (
              <SummaryEmptyStateIcon icon={faHourglassClock} />
            )}
          </Box>
        </SummaryBox>
      </Grid>
      <Grid item xs={3}>
        <SummaryBox variant="outlined">
          <Typography variant="body1">
            <strong>Lowest Performing Indicators</strong>
          </Typography>
          <Box>
            {lowestPerforming?.length > 0 ? (
              <TopThree data={lowestPerforming} decimalPlaces={1} marginBottom={0} />
            ) : (
              <SummaryEmptyStateIcon icon={faHourglassClock} />
            )}
          </Box>
        </SummaryBox>
      </Grid>
    </Grid>
  );
};

export default ObserverSummary;
